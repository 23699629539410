const options = function (options) {
  var defaultOpts = {
    allowDownload: true,
    // Should be hash with overrides that match size variables at start of base chart, e.g.
    // { outerHeight: 300, margin: { top: 0, left: 20 } }
    boxModel: undefined,
    footer: undefined,
    footerImage: undefined,
    showFooterInChart: false,
    invertAxes: false,
    legendPosition: 'bottom', // Currently supports: baseline, bottom, right, and top
    legendDirection: 'vertical', // Currently supports Stacked bar charts only, vertical or horizontal
    lineLegendSize: 'small', // Currently supports: small for either legend position and full-width for bottom
    itemsPerLegendRowWhenHorizontal: 0, // The default, 0, places all items on one line
    lollipopPoints: false,
    longestLabel: undefined, // A numeric value, for when charts are chunked, but you want the same final size
    maxBarWidth: undefined,
    pointSize: 10,
    secondYAxis: false,
    // This is for when multiple charts are on the same page, but not necessarily
    // with the same data sets, and you want to shift the colors a bit to get them to line up
    seriesNumStartOffset: 0,
    settings: {},
    showLegend: true,
    title: undefined,
    valueDisplayDecimalPlaces: 1,
    valueDisplayPrefix: '',
    valueDisplaySuffix: '',
    valueToolTips: 'hover',
    valueToolTipDecimalPlaces: undefined,
    valueToolTipsInclSeriesName: false,
    valueDisplayInline: false, //Same as `always-pdf` for valueToolTips
    xAxisLabel: undefined,
    xAxisRange: undefined, //An array of 2 numbers, [XMin, XMax], overrides normal axis labeling
    xAxisTickDisplayPrefix: '', //Currently only supported by Histogram
    xAxisTickDisplaySuffix: '', //Currently only supported by Histogram
    xAxisVerticalLabels: false,
    yStep: undefined,
    yAxisLabel: undefined,
    yAxisGridLines: false,
    yAxisRange: undefined, //An array of 2 numbers, [YMin, YMax]
    yTickCount: undefined,
    showYAxis: true,
    groupLabels: undefined,
    groupBySeries: false,
    leftMarginBuffer: 30, //A single number of forced extra space for the left axis, not sure why it's in there and I need it gone for a chart but don't want to break charts that use it
    legendWidth: 190, //Should probably be calculated based on labels, but that can get dangerous as there isn't an easy way to do so.
    chartValueBasis: 0, // For bars and lollipops, the "base" placement
    drawPointLabels: true, // When the only series is a Point series in the chart this is needed to control if there is text next to each point or not
    captureContainerInDownload: false, // If true, the image will capture the surrounding container as well as the chart
    includeAnnotationsInDownload: true, // If true, the image will include the title, footer and any report options in the image
  };

  var opts = defaultOpts;
  if (typeof options == 'object') {
    opts = $.extend(defaultOpts, options);
  }

  if (['always', 'never', 'hover', 'always-pdf'].indexOf(opts.valueToolTips) == -1) {
    opts.valueToolTips = 'hover';
  }

  if (opts.valueToolTipDecimalPlaces === undefined) {
    opts.valueToolTipDecimalPlaces = opts.valueDisplayDecimalPlaces;
  }

  var getters = [
    'valueDisplayDecimalPlaces',
    'yAxisLabel',
    'yStep',
    'valueDisplayPrefix',
    'valueDisplaySuffix',
    'settings',
    'title',
    'footer',
    'footerImage',
    'valueToolTips',
    'invertAxes',
    'showLegend',
    'allowDownload',
    'boxModel',
    'yAxisRange',
    'xAxisLabel',
    'xAxisRange',
    'pointSize',
    'legendPosition',
    'maxBarWidth',
    'lineLegendSize',
    'valueToolTipDecimalPlaces',
    'seriesNumStartOffset',
    'longestLabel',
    'secondYAxis',
    'xAxisTickDisplayPrefix',
    'xAxisTickDisplaySuffix',
    'xAxisVerticalLabels',
    'yTickCount',
    'groupLabels',
    'groupBySeries',
    'leftMarginBuffer',
    'legendDirection',
    'legendWidth',
    'showYAxis',
    'valueDisplayInline',
    'lollipopPoints',
    'itemsPerLegendRowWhenHorizontal',
    'chartValueBasis',
    'drawPointLabels',
    'valueToolTipsInclSeriesName',
    'captureContainerInDownload',
    'includeAnnotationsInDownload',
  ];
  var i = getters.length;
  while (i--) {
    let key = getters[i];
    // Define getter functions
    (function (t, o, k) {
      var funcName = 'get' + firstUpper(k);
      t[funcName] = function () {
        return o[k];
      };
    })(this, opts, key);
  }

  var hassers = [
    'footer',
    'title',
    'yAxisLabel',
    'yStep',
    'boxModel',
    'yAxisRange',
    'xAxisLabel',
    'xAxisRange',
    'maxBarWidth',
    'longestLabel',
    'yTickCount',
    'groupLabels',
    'footerImage',
  ];
  var j = hassers.length;
  while (j--) {
    let key = hassers[j];
    // Define hasser functions
    (function (t, o, k) {
      var funcName = 'has' + firstUpper(k);
      t[funcName] = function () {
        return o[k] !== undefined;
      };
    })(this, opts, key);
  }

  if (this.hasTitle()) {
    //This strips HTML by just pulling out the text
    opts.title = $('<p>' + opts.title + '</p>').text();
  }

  this.hasValidBoxModel = function () {
    if (this.hasBoxModel()) {
      var bm = this.getBoxModel();
      var keys = hashKeys(bm);
      var badKeys = keys.filter(function (e) {
        return ['outerHeight', 'outerWidth', 'margin'].indexOf(e) == -1;
      });
      if (badKeys.length) {
        // eslint-disable-next-line no-console
        console.log(badKeys);
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(bm, 'margin')) {
        var mKeys = hashKeys(bm.margin);
        var badMKeys = mKeys.filter(function (e) {
          return ['top', 'right', 'bottom', 'left'].indexOf(e) == -1;
        });
        if (badMKeys.length) {
          // eslint-disable-next-line no-console
          console.log(badMKeys);
          return false;
        }
      }

      return true;
    }
    return false;
  };

  this.settingKeys = function () {
    return hashKeys(opts.settings);
  };

  this.showYAxisGridLines = function () {
    return opts.yAxisGridLines;
  };

  function firstUpper(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }

  function hashKeys(hash) {
    var keys = [];
    for (var key in hash) {
      if (Object.prototype.hasOwnProperty.call(hash, key)) {
        keys.push(key);
      }
    }
    return keys;
  }

  return this;
};

export default options;
