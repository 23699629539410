function openWindow(theURL, theHeight, theWidth, features) {
  if (!theHeight) theHeight = 400;
  if (!theWidth) theWidth = 400;

  if (!features)
    features = 'location=no,menubar=no,toolbar=no,status=no,scrollbars=yes,resizable=yes';

  var newWindow = window.open(
    theURL,
    'newWindow',
    'height=' + theHeight + ',width=' + theWidth + ',' + features,
  );
  if (newWindow) {
    newWindow.focus();
  }
}

window.openWindow = openWindow;
