import d3 from './d3';
import BaseChart from './BaseChart';
const PieChart = function (wrapper, pieSeries, options, averageCharacterWidth) {
  var self = this;
  self.outerWidth = 400;
  self.outerHeight = 200;
  self.margin = { top: 10, right: 10, bottom: 10, left: 10 };
  // Apply the constructor of the "Parent" to `this` instance
  BaseChart.apply(self, [wrapper, 0, options, averageCharacterWidth]);
  self.chartClass = 'pie-chart';

  var r = 70,
    textOffset = 7,
    //D3 helper function to populate pie slice parameters from array data
    donut = d3
      .pie()
      .value(function (d) {
        return d.value;
      })
      .sort(null),
    //D3 helper function to draw arcs, populates parameter "d" in path object
    arc = d3
      .arc()
      .startAngle(function (d) {
        return d.startAngle;
      })
      .endAngle(function (d) {
        return d.endAngle;
      })
      .outerRadius(r)
      .innerRadius(0);

  self.draw = function () {
    var svg = self.createBaseChart(),
      piePlacementTransform = 'translate(' + (self.width * 2) / 3 + ',' + self.height / 2 + ')',
      //GROUP FOR ARCS/PATHS
      arc_group = svg.append('svg:g').attr('class', 'arc').attr('transform', piePlacementTransform),
      //GROUP FOR LABELS
      label_group = svg
        .append('svg:g')
        .attr('class', 'label_group')
        .attr('transform', piePlacementTransform),
      pieLayerData = $.map(pieSeries.data, function (d, i) {
        return { value: d, origIndex: i };
      }),
      pieData = donut(pieLayerData),
      filteredPieData = pieData.filter(function (element, index) {
        element.value = pieLayerData[index].value;
        return element.value > 0;
      });

    if (filteredPieData.length > 0) {
      //DRAW ARC PATHS
      var paths = arc_group.selectAll('path').data(filteredPieData);
      paths
        .enter()
        .append('svg:path')
        .attr('stroke', 'white')
        .attr('stroke-width', 0.5)
        .attr('class', function (d) {
          return self.seriesClass('arc', d.data.origIndex);
        })
        .attr('d', function (d) {
          return arc(d);
        });

      //DRAW TICK MARK LINES FOR LABELS
      var lines = label_group.selectAll('line').data(filteredPieData);
      lines
        .enter()
        .append('svg:line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', -r - 3)
        .attr('y2', -r - 6)
        .attr('stroke', 'gray')
        .attr('transform', function (d) {
          return 'rotate(' + ((d.startAngle + d.endAngle) / 2) * (180 / Math.PI) + ')';
        });

      //DRAW LABELS WITH ACTUAL VALUES
      var valueLabels = label_group.selectAll('text.value').data(filteredPieData);
      valueLabels
        .enter()
        .append('svg:text')
        .attr('class', 'value')
        .attr('transform', function (d) {
          var ang = (d.startAngle + d.endAngle - Math.PI) / 2;
          return (
            'translate(' +
            Math.cos(ang) * (r + textOffset) +
            ',' +
            Math.sin(ang) * (r + textOffset) +
            ')'
          );
        })
        .attr('dy', function (d) {
          var arcCenter = (d.startAngle + d.endAngle) / 2;
          if (arcCenter > Math.PI / 2.2 && arcCenter < Math.PI * 1.6) {
            return 5;
          } else {
            return 0;
          }
        })
        .attr('text-anchor', function (d) {
          // If the center of the angle is on the right half of the pie
          // or if this is the last slice, and it's tiny
          // Then have the text go to the right.
          if ((d.startAngle + d.endAngle) / 2 < Math.PI || d.startAngle >= 2 * Math.PI - 0.1) {
            return 'start';
          } else {
            return 'end';
          }
        })
        .text(function (d) {
          return self.formatValue(d.value);
        });

      self.drawBasicColorLegend(svg, pieSeries, 0, 50, 'arc');
    }
  };
};
export default PieChart;
