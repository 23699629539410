/* `tfk_lib_util` contains utility functions that are
universally applicable and supplement the
[jQuery utilties](http://api.jquery.com/category/utilities/)
 -Jared 2013-08-27 */

// eslint-disable-next-line no-unused-vars, no-shadow-restricted-names
(function (tfk, $, undefined) {
  /*
  In Ruby, "An object is blank if it’s false, empty, or a whitespace
  string. For example, “”, “ ”, nil, [], and {} are all blank.".  My
  implementation depends on jQuery, for $.isArray and $.isEmptyObject.
  -Jared 2013-08-27
  */
  tfk.isBlank = function (obj) {
    var typ = typeof obj;
    if (typ === 'undefined') {
      return true;
    } else if (typ === 'boolean') {
      return !obj;
    } else if (typ === 'number' || typ === 'function' || typ === 'xml') {
      return false;
    } else if (typ === 'string') {
      return /^\s*$/.test(obj);
    } else if ($.isArray(obj)) {
      return obj.length === 0;
    } else if (typ === 'object') {
      return obj === null || $.isEmptyObject(obj);
    } else {
      throw 'Unexpected type: ' + typ;
    }
  };
})((window.tfk = window.tfk || {}), jQuery);
