import d3 from './d3';
import BaseBarChart from './BaseBarChart';

const BarChart = function (
  wrapper,
  barSeries,
  pntSeries,
  lineSeries,
  options,
  averageCharacterWidth,
) {
  var self = this;
  // Apply the constructor of the "Parent" to `this` instance
  BaseBarChart.apply(self, [
    wrapper,
    barSeries,
    pntSeries,
    lineSeries,
    options,
    averageCharacterWidth,
  ]);

  self.barXOffset = function (x, d) {
    if (options.getGroupBySeries()) {
      return (x.bandwidth() - self.barWidth(x, d)) / 2;
    } else {
      return (x.bandwidth() - self.barWidth(x, d)) / 2 + x.bandwidth() * d.xPos;
    }
  };

  self.barWidth = function (x, d) {
    return Math.min(self.maxBarWidth, (x.bandwidth() * 0.75) / d.xTot);
  };

  self.drawBarLegend = function (svg, legendPosition) {
    var lX, lY;
    switch (legendPosition) {
      case 'bottom':
        lX = 0;
        lY = self.outerHeight - self.margin.bottom + 40;
        break;
      case 'baseline':
        lX = 0;
        lY = self.outerHeight - barSeries.length * self.legendItemHeight - 14;
        break;
      case 'right':
        lX = self.outerWidth - options.getLegendWidth();
        lY = self.margin.top + 25;
        break;
      case 'top':
        lX = 0;
        lY = 10 - self.margin.top;
    }
    self.drawBasicColorLegend(svg, barSeries, lX, lY, 'bar', pntSeries.length > 0);
  };

  // Creates D3 data "layers" from the array of Series that was passed in
  self.layersFromArrayOfCols = function (d) {
    var l = [];
    d3.range(d.length).map(function (cNum) {
      l[cNum] = [];
      d3.range(d[0].len()).map(function (lNum) {
        l[cNum][lNum] = {
          x: lNum,
          xPos: -0.4 + (0.4 / d.length) * (2 * cNum + 1),
          xTot: d.length,
          seriesNum: cNum,
          y: parseFloat(d[cNum].data[lNum]) - options.getChartValueBasis(),
          y0: options.getChartValueBasis(),
        };
      });
    });
    return l;
  };

  // This is to provide padding at the top and bottom of data
  self.modifyYStackRanges = function () {
    if (options.getChartValueBasis() === null) {
      self.yStackMin = self.yStackMin - Math.abs(self.yStackMin * 0.1);

      self.yStackMax = self.yStackMax + Math.abs(self.yStackMax * 0.1);
    } else {
      self.yStackMin =
        self.yStackMin > options.getChartValueBasis()
          ? options.getChartValueBasis()
          : options.getChartValueBasis() - (options.getChartValueBasis() - self.yStackMin) * 1.1;

      self.yStackMax =
        self.yStackMax < options.getChartValueBasis()
          ? options.getChartValueBasis()
          : self.yStackMax + (self.yStackMax - options.getChartValueBasis()) * 0.1;
    }
  };

  self.reserveSeriesNumbers = function () {
    // We want the Point series to get colored first such that when in
    // Report Writer the Focus Unit (point series) is the same color
    // as the Focus unit in T&G (first series to appear)
    for (var p = 0; p < pntSeries.length; p++) {
      self.seriesClass('point', p); //Reserve the series number for points
    }
  };
};

export default BarChart;
