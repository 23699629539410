$(document).ready(function () {
  $('.checkbox-list-multi-select').each(function () {
    var $chkboxes = $(this).find('input[type=checkbox]'),
      $labels = $(this).find('input[type=checkbox] + label'),
      lastChecked = null,
      activeSelection = false;

    $labels
      .click(function (e) {
        if (activeSelection) {
          return;
        }

        var thiscbk = $(this).prev().get()[0];
        if (!lastChecked) {
          lastChecked = thiscbk;
          return;
        }

        if (e.shiftKey) {
          // removeAllRanges is FF specific, as this problem is FF specific
          if (!activeSelection && window.getSelection().removeAllRanges) {
            window.getSelection().removeAllRanges();
          }

          e.preventDefault();
          var start = $chkboxes.index(thiscbk);
          var end = $chkboxes.index(lastChecked);

          $chkboxes
            .not(':disabled')
            .slice(Math.min(start, end), Math.max(start, end) + 1)
            .prop('checked', lastChecked.checked);
        }

        lastChecked = thiscbk;
      })
      .mousedown(function () {
        activeSelection = window.getSelection().toString().length != 0;
      });
  });
});
