function setCheckboxes(fieldName, anID, onOrOff) {
  var anElement,
    theForm,
    changed = [];

  anElement = document.getElementById(anID);
  if (anElement) theForm = anElement.form;

  if (theForm) {
    var theCB = theForm[fieldName];
    if (theCB.length) {
      for (var i = 0; i < theCB.length; i++)
        if (theCB[i].checked != onOrOff) {
          theCB[i].checked = onOrOff;
          changed.push(theCB[i]);
        }
    } else {
      if (theCB.checked != onOrOff) {
        theCB.checked = onOrOff;
        changed.push(theCB);
      }
    }
  }

  $(changed).change();
}

window.setCheckboxes = setCheckboxes;
