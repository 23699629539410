/*
 * In the past, we used a library from 2007 called sorttable.js
 * http://www.kryogenix.org/code/browser/sorttable/
 *
 * In July 2019, we switched to https://datatables.net, at that time the
 * most popular table-sorting library on NPM.
 **/

window.initSortableTable = function (element) {
  // First, determine which columns are NOT sortable.
  var $rows = $(element).find('thead > tr');
  if ($rows.length != 1 && $rows.length != 2) {
    throw (
      'Failed to sort a sortable table: ' +
      $(element).id +
      ': Expected exactly one or two thead rows, got: ' +
      $rows.length
    );
  }
  var $row1 = $rows.eq(0);
  var $row2Cols = $rows.eq(1).children();
  var row2ColIdx = 0;
  if ($row1.children().length == 0) {
    throw 'Expected the thead row to have children';
  }
  var columns = [];
  $row1.children().each(function () {
    if ($(this).attr('colspan') !== undefined) {
      for (var i = 0; i < $(this).attr('colspan'); i++) {
        columns.push($row2Cols.eq(row2ColIdx).hasClass('nosort') ? { orderable: false } : null);
        row2ColIdx++;
      }
    } else {
      columns.push($(this).hasClass('nosort') ? { orderable: false } : null);
    }
  });
  try {
    $(element).DataTable({
      autoWidth: false,
      columns: columns,
      info: false,
      order: [], // disable initial sort, because CF does that.
      paging: false,
      stateSave: false,
      searching: false,
    });
  } catch {
    // eslint-disable-next-line no-console
    console.log('Datatables failed to load');
  }
};

$(document).ready(function () {
  // TODO: Do we need to care about checkboxes or radios?
  function getSortValueFromDOMNode($node) {
    return (function () {
      if ($node.is('select')) {
        return $node.find('option:selected').text();
      } else if ($node.is(':input')) {
        // The `:input` pseudoselector should work for normal `input` and `textarea`.
        return $node.val();
      } else {
        // We don't expect tafkan tables to have, eg. `<div>foo</div>` but just in case ..
        return $node.text();
      }
    })($node).trim();
  }

  function looksLikeHTML(string) {
    var numLeftBrackets = (string.match(/</g) || []).length;
    var numRightBrackets = (string.match(/>/g) || []).length;
    return numLeftBrackets > 0 && numLeftBrackets == numRightBrackets;
  }

  if ($.fn.DataTable !== undefined) {
    // To support table cells containing inputs, we register a cell type `detect`
    // function.
    // https://datatables.net/examples/plug-ins/sorting_auto.html
    $.fn.DataTable.ext.type.detect.unshift(function (innerHtmlOfCellAsString) {
      if (looksLikeHTML(innerHtmlOfCellAsString)) {
        return 'tafkan-input-trim-val';
      } else {
        return null; // normal table cell, use normal `order` function
      }
    });

    // Register the sorting function for the `tafkan-input-trim-val` type.
    // "Pre", as in "pre-processor". The step that happens before sorting.
    $.fn.DataTable.ext.type.order['tafkan-input-trim-val-pre'] = function (
      innerHtmlOfCellAsString,
    ) {
      try {
        var parsed = $(innerHtmlOfCellAsString);
      } catch {
        // The table cell looked like it contained an HTML input, but we were
        // wrong. We can just sort by string value.
        return innerHtmlOfCellAsString;
      }
      var value = getSortValueFromDOMNode(parsed).replace(/,/g, '');
      var float = parseFloat(value);
      if (_.isNaN(float)) {
        return value;
      } else {
        return float;
      }
    };
  }

  $('table.sortable').each(function (index, element) {
    window.initSortableTable(element);
  });
});
