// -----------------------------
// Allow users to uncheck certain radio buttons.
$(document).ready(function () {
  /* radioLastValues is used to store the state of radio buttons on a page. We need do
    this because you cannot determine the previous state of a radio button during
    the click event. It will always be "checked" at that time. */
  var radioLastValues = new Object();

  // Pre-populate radioLastValues for all checked radios on the page.
  $('input.decheckable[name]:checked').each(function () {
    setRadioLastValue(this.name, this.value);
  });

  // Handle clicks on de-checkable radios
  $(document).on('click', 'input.decheckable[name]', function () {
    if (radioLastValues[this.name] === this.value) {
      // We are un-checking a previously-checked radio
      this.checked = false;
      $(this).trigger('change');
      setRadioLastValue(this.name, null);
    } else if (this.checked) {
      /* If the radio got checked by the click, update radioLastValues. The check for
        `this.checked` above may always be true, but there might be edge cases, like
        if another event handler prevented the default action of the click. */
      setRadioLastValue(this.name, this.value);
    }
  });

  /* Update radioLastValues before a radio label is clicked. This works with regular
    text labels and with our custom-looking radios. This is necessary for radios
    that get checked by JS after the initial page load. */
  $('input.decheckable[id][name]').each(function () {
    var radio = this;
    $('[for="' + this.id + '"]').on('mousedown', function () {
      var currentValue = $('[name=' + radio.name + ']:checked').val();
      setRadioLastValue(radio.name, currentValue);
    });
  });

  function setRadioLastValue(name, value) {
    radioLastValues[name] = value;
  }
});
