import BaseChart from './BaseChart';
import d3 from './d3';
import isWkHTMLToPDF from './isWkHTMLToPDF';

/**
 * wrapper: valid d3 selector
 * bins: Array of bins
 *  Each bin has two keys, min and count
 * aggregates: Obj with keys stddev, avg, n, and h for use in the dist curvey formula
 * addlLines: Array of data points with two keys: label, value, drawn as vertical lines
 * options: A charting.Options object
 */
const BaseHistogramChart = function (
  wrapper,
  bins,
  aggregates,
  addlLines,
  options,
  averageCharacterWidth,
) {
  var self = this;
  var largestSeriesSetSize = addlLines.length;
  if (!options.getShowLegend()) {
    largestSeriesSetSize = 0; //Don't create room for the legends
  }

  // Apply the constructor of the "Parent" to `this` instance
  BaseChart.apply(self, [wrapper, largestSeriesSetSize, options, averageCharacterWidth]);

  self.chartClass = 'histogram bar-chart';

  var x, y;

  self.draw = function () {
    var bars = [
        bins.map(function (bin, idx) {
          return {
            x: idx,
            y: bin.COUNT,
          };
        }),
      ],
      vlines = addlLines.map(function (ln) {
        return [ln.VALUE];
      }),
      curveFunc = (function (stddev, mean, h, n) {
        return function (x) {
          return (
            (1 / (stddev * Math.sqrt(2 * Math.PI))) *
            Math.exp(-(Math.pow(x - mean, 2) / (2 * Math.pow(stddev, 2)))) *
            h *
            n
          );
        };
      })(aggregates.STDDEV, aggregates.MEAN, aggregates.H, aggregates.N),
      curvePrecision = Math.ceil(50 / bins.length), // Number of lines per section
      curvePoints = [[bins[0].MIN]];

    var curveStep = aggregates.H / curvePrecision;
    bins.forEach(function (bin) {
      for (var i = 1; i <= curvePrecision; i++) {
        curvePoints[0].push(bin.MIN + curveStep * i);
      }
    });

    self.yStackMax = d3.max(bins, function (bin) {
      return bin.COUNT;
    });
    if (curveFunc(aggregates.MEAN) > self.yStackMax) {
      self.yStackMax = Math.ceil(curveFunc(aggregates.MEAN));
    }
    self.yStackMin = 0;
    var largestTickLength = String(self.formatValue(self.yStackMax)).length;
    var leftChange =
      largestTickLength * averageCharacterWidth - self.margin.left + options.getLeftMarginBuffer();
    if (options.hasYAxisLabel()) {
      leftChange += 20;
    } //Ensure room for the axis label if needed
    if (leftChange > 0) {
      self.margin.left += leftChange;
      self.outerWidth += leftChange;
      self.width += leftChange;
    }

    const xMax = bins[0].MIN + aggregates.H * bins.length;
    const longestLabel = xAxisTickFormat(bins.length);
    const chartHeight = self.height - longestLabel.length * averageCharacterWidth;

    var xAxisRange = [0, self.width],
      yAxisRange = [chartHeight, 0],
      xAxisPlacement = 'translate(0,' + chartHeight + ')',
      yAxisPlacement = 'translate(0,0)',
      xDomain = d3.range(bins.length);

    x = d3.scaleBand().domain(xDomain).rangeRound(xAxisRange);

    var xLines = d3.scaleLinear().domain([bins[0].MIN, xMax]).range(xAxisRange);

    y = d3.scaleLinear().domain([self.yStackMin, self.yStackMax]).range(yAxisRange);

    var xAxis = d3
      .axisBottom()
      .scale(x)
      .tickSize(3)
      .tickPadding(6)
      .tickValues(d3.range(bins.length))
      .tickFormat(xAxisTickFormat);

    var yAxis = d3
      .axisLeft()
      .scale(y)
      .tickSize(3)
      .tickPadding(6)
      .tickFormat(function (d) {
        if (options.getValueDisplayDecimalPlaces() == 0 && parseInt(d) != parseFloat(d)) {
          // If it's 0 DP and this is a fractional step, just don't label the tick
          return '';
        } else {
          return self.formatValue(d);
        }
      });

    if (options.hasYTickCount()) {
      yAxis.ticks(options.getYTickCount());
    }

    var svg = self.createBaseChart();

    /* Draw the Y Axis Grid if they want it. Do this first such
        that it's under everything else */
    if (options.showYAxisGridLines()) {
      var axisBase = options.getInvertAxes() ? d3.axisBottom() : d3.axisLeft();
      svg
        .append('g')
        .attr('class', 'y axis grid')
        .attr('transform', yAxisPlacement)
        .call(
          axisBase
            .scale(y)
            .tickSize(-(options.getInvertAxes() ? self.height : self.width), 0, 0)
            .tickFormat('')
            .ticks(yAxis.ticks()[0]),
        )
        .style({ 'stroke-width': '1px' });
    }

    var bar = svg
      .append('g')
      .attr('class', 'bar-container')
      .selectAll('.bar')
      .data(bars)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('bar', i);
      });

    var line = svg
      .append('g')
      .attr('class', 'line-container curve-container')
      .selectAll('.line')
      .data(curvePoints)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('line', i);
      });

    var vline = svg
      .append('g')
      .attr('class', 'line-container vline-container')
      .selectAll('.line')
      .data(vlines)
      .enter()
      .append('g')
      .attr('class', function (d, i) {
        return self.seriesClass('line', i + 1);
      });

    // Draw all the bars!
    var rectBar = svgObjBase(bar, 'rect');

    rectBar
      .attr('x', function (d) {
        return x(d.x);
      })
      .attr('width', x.bandwidth())
      .attr('y', function (d) {
        return y(d.y);
      })
      .attr('height', function (d) {
        return y(0) - y(d.y);
      });

    // Draw all the Vertical lines (similar idea to points)
    svgObjBase(vline, 'path').attr('d', function (d) {
      return 'M' + xLines(d) + ',' + y(self.yStackMin) + 'L' + xLines(d) + ',' + y(self.yStackMax);
    });

    // Draw some lines to represent the curve function
    svgObjBase(line, 'path').attr('d', function (d) {
      return (
        'M' +
        xLines(d) +
        ',' +
        y(curveFunc(d)) +
        'L' +
        xLines(d + curveStep) +
        ',' +
        y(curveFunc(d + curveStep))
      );
    });

    // Add X-Axis
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', xAxisPlacement)
      .call(xAxis)
      .style({ 'stroke-width': '1px' });

    /* Shift the markers to the start of the bands, and rotate the labels */
    var prevTranslation,
      positionXAxisLabel = function (tick) {
        var halfABar = x.bandwidth() / 2;
        var shiftLeft = halfABar;
        var txt = tick.selectAll('text');
        var transform;
        if (
          txt.text() == xAxisTickFormat(bins.length) &&
          Array.isArray(prevTranslation) &&
          prevTranslation.length > 0
        ) {
          prevTranslation[0] = prevTranslation[0] + x.bandwidth();
          transform = prevTranslation;
        } else {
          transform = self.getTranslation(tick.attr('transform'));
          prevTranslation = transform;
        }
        tick.attr(
          'transform',
          'translate(' + (transform[0] - shiftLeft) + ',' + transform[1] + ')',
        );
        var bnds = txt.node().getBBox();
        txt.attr(
          'transform',
          'rotate(90 ' +
            halfABar / 2 +
            ',0) translate(' +
            (bnds.width / 2 + halfABar / 2 + 10) +
            ',' +
            (halfABar / 2 - 14.5) +
            ' )',
        );
      };
    svg
      .call(xAxis)
      .selectAll('.tick')
      .each(function () {
        var tick = d3.select(this);
        positionXAxisLabel(tick);
      });

    var lastTick = svg.select('.x.axis').append('g').attr('class', 'tick').attr('opacity', 1);
    lastTick.append('line').attr('y2', 3);
    lastTick.append('text').text(xAxisTickFormat(bins.length)).attr('dy', '1.71em');
    positionXAxisLabel(svg.select('.x.axis g:last-child'));

    // Add Y-Axis
    if (options.getShowYAxis()) {
      svg
        .append('g')
        .attr('class', 'y axis')
        .attr('transform', yAxisPlacement)
        .call(yAxis)
        .style({ 'stroke-width': '1px' });
    }

    // This may also need to be run if there is an x axis label
    if (options.getShowLegend()) {
      drawLineLegend(svg, options.getLegendPosition());
    }

    self.formatLabels(svg.selectAll('.axis.x g.tick.major').nodes());

    drawAxisLabels(svg);
    self.removeDefaultStyleAttrsFromAxesAndWrapper();

    self.drawFooterImage(options, svg);

    if (!isWkHTMLToPDF() && options.getAllowDownload()) {
      this.addDownloadLink(wrapper, averageCharacterWidth);
    }

    // Finally, resize the parent SVG to fit the contents since the x-axis labels are rotated
    self.adjustSVGBounds(svg);
  };

  /* Private methods */
  /* Because function definitions are hoisted, we can define them down here
      but still override them above for children */

  function drawLineLegend(svg, legendPosition) {
    var lX, labelWidth, lY;
    switch (legendPosition) {
      case 'bottom':
        lX = 0;
        labelWidth = self.outerWidth;
        var labelspacer = options.hasXAxisLabel() ? 20 : 0;
        lY = self.outerHeight - self.margin.bottom + 25 + labelspacer;
        break;
      case 'right':
        lX = self.outerWidth - 190;
        lY = self.outerHeight / 2;
        labelWidth = 150;
        break;
      case 'top':
        lX = 0;
        lY = 10 - self.margin.top;
        break;
    }
    var w = 35;
    var legend = svg.append('g').attr('class', 'legend');
    var lines = legend.append('g');
    var labels = legend.append('g');
    /* Add the dist curve line first */
    var distPathY = lY - (self.legendItemHeight - 4) / 2;
    lines
      .append('g')
      .attr('class', self.seriesClass('line', 0))
      .append('path')
      .attr('d', 'M' + lX + ',' + distPathY + 'L' + (lX + w) + ',' + distPathY);
    labels
      .append('text')
      .attr('x', lX + 5 + w)
      .attr('y', lY)
      .text(self.wordWrap('Distribution Curve', labelWidth));
    $.map(addlLines, function (line, i) {
      var yOff = (i + 1) * self.legendItemHeight;
      var tY = lY + yOff;
      var pathY = tY - (self.legendItemHeight - 4) / 2;
      lines
        .append('g')
        .attr('class', self.seriesClass('line', i + 1)) // the curve function uses the first line class
        .append('path')
        .attr('d', 'M' + lX + ',' + pathY + 'L' + (lX + w) + ',' + pathY);
      labels
        .append('text')
        .attr('x', lX + 5 + w)
        .attr('y', tY)
        .text(self.wordWrap(line.LABEL, labelWidth));
    });
    self.formatLabels(labels.nodes());
  }

  // Draws a rotated label for the Y Axis
  function drawAxisLabels(svg) {
    if (options.hasYAxisLabel()) {
      // yAxisLabel
      svg
        .append('text')
        .style('text-anchor', 'middle')
        .text(options.getYAxisLabel())
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - self.margin.left)
        .attr('x', 0 - self.height / 2)
        .attr('dy', '1em');
    }
    if (options.hasXAxisLabel()) {
      svg
        .append('text')
        .style('text-anchor', 'middle')
        .text(options.getXAxisLabel())
        .attr('x', self.width / 2)
        .attr('y', self.height + 30);
    }
  }

  /* Replaces all `objType` in a layer with new `objType`s mapped to
      the data for that layer */
  function svgObjBase(layerSet, objType) {
    return layerSet
      .selectAll(objType)
      .data(function (d) {
        return d;
      })
      .enter()
      .append(objType);
  }

  function xAxisTickFormat(d) {
    return (
      options.getXAxisTickDisplayPrefix() +
      self.formatValue(d < bins.length ? bins[d].MIN : bins[d - 1].MIN + aggregates.H) +
      options.getXAxisTickDisplaySuffix()
    );
  }
};
export default BaseHistogramChart;
