function openHelp(mode, scriptName) {
  var modeParam = '';
  if (mode != undefined) {
    modeParam = 'mode=' + mode + '&';
  }
  window.openWindow(
    '/app/views/popup/win_helpforpages.cfm?' + modeParam + 'scriptname=' + scriptName,
    800,
    800,
  );
}

window.openHelp = openHelp;
