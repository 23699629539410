/*
 * NumberFormat v1.0
 * 04/13/2000
 * K. Jenci
 * http://www.mredkj.com
 */

/*
 * NumberFormat -The constructor
 * num - The number to be formatted
 */
function NumberFormat(num) {
  // member variables
  this.num;
  this.isCommas;
  this.isCurrency;
  this.currencyPrefix;
  this.places;

  // external methods
  this.setNumber = setNumberNF;
  this.toUnformatted = toUnformattedNF;
  this.setCommas = setCommasNF;
  this.setCurrency = setCurrencyNF;
  this.setCurrencyPrefix = setCurrencyPrefixNF;
  this.setPlaces = setPlacesNF;
  this.toFormatted = toFormattedNF;

  // internal methods
  this.getRounded = getRoundedNF;
  this.preserveZeros = preserveZerosNF;

  // setup
  this.setNumber(num == null ? 0 : num);
  this.setCommas(true);
  this.setCurrency(true);
  this.setCurrencyPrefix('$');
  this.setPlaces(2);
}

/*
 * setNumber - Sets the number
 * num - The number to be formatted
 */
function setNumberNF(num) {
  this.num = num;
}

/*
 * toUnformatted - Returns the number as is (a number)
 */
function toUnformattedNF() {
  return this.num;
}

/*
 * setCommas - Sets a switch that indicates if there should be commas
 * isC - true, if should be commas; false, if no commas
 */
function setCommasNF(isC) {
  this.isCommas = isC;
}

/*
 * setCurrency - Sets a switch that indicates if should be displayed as currency
 * isC - true, if should be currency; false, if not currency
 */
function setCurrencyNF(isC) {
  this.isCurrency = isC;
}

/*
 * setCurrencyPrefix - Sets the symbol that precedes currency.
 * cp - The symbol
 */
function setCurrencyPrefixNF(cp) {
  this.currencyPrefix = cp;
}

/*
 * setPlaces - Sets the precision of decimal places
 * p - The number of places
 */
function setPlacesNF(p) {
  this.places = p;
}

/*
 * toFormatted - Returns the number formatted according to the settings (a string)
 */
function toFormattedNF() {
  var pos;
  var n = this.num;

  // round decimal places
  n = this.getRounded(n);
  n = this.preserveZeros(n); // this step makes n into a string.

  if (this.isCommas) {
    pos = n.indexOf('.');
    if (pos == -1) {
      pos = n.length;
    }
    while (pos > 0) {
      pos -= 3;
      if (pos <= 0) break;
      n = n.substring(0, pos) + ',' + n.substring(pos, n.length);
    }
  }

  if (this.isCurrency) {
    // add dollar sign in front
    n = this.currencyPrefix + n;
  }

  // Remove leading comma if number is 3n digits and negative, e.g. -232,323
  // Otherwise this will return -,232,323

  n = n.replace(/^-,/, '-');

  return n;
}

/*
 * getRounded - Used internally to round a value
 * val - The number to be rounded
 */
function getRoundedNF(val) {
  var factor;
  var i;

  // round to a certain precision
  factor = 1;
  for (i = 0; i < this.places; i++) {
    factor *= 10;
  }
  val *= factor;
  val = Math.round(val);
  val /= factor;

  return val;
}

/*
 * preserveZeros - Used internally to make the number a string
 * 	that preserves zeros at the end of the number
 * val - The number
 */
function preserveZerosNF(val) {
  var i;

  // make a string - to preserve the zeros at the end
  val = val + '';
  if (this.places == 0) return val; // leave now. no zeros are necessary

  var decimalPos = val.indexOf('.');
  if (decimalPos == -1) {
    val += '.';
    for (i = 0; i < this.places; i++) {
      val += '0';
    }
  } else {
    var actualDecimals = val.length - 1 - decimalPos;
    var difference = this.places - actualDecimals;
    for (i = 0; i < difference; i++) {
      val += '0';
    }
  }

  return val;
}

window.NumberFormat = NumberFormat;
window.setNumberNF = setNumberNF;
window.toUnformattedNF = toUnformattedNF;
window.setCommasNF = setCommasNF;
window.setCurrencyNF = setCurrencyNF;
window.setCurrencyPrefixNF = setCurrencyPrefixNF;
window.setPlacesNF = setPlacesNF;
window.toFormattedNF = toFormattedNF;
window.getRoundedNF = getRoundedNF;
window.preserveZerosNF = preserveZerosNF;
