function Messenger() {
  // 2d array - first dim is message name, second is listener object
  this.arListeners = new Array();

  this.addListener = function (messageName, objListener) {
    //alert('adding Listener of type ' + typeof objListener + ' for message ' + messageName);
    if (!this.arListeners[messageName]) this.arListeners[messageName] = new Array();
    this.arListeners[messageName].push(objListener);
    //alert('new listener is of type ' + typeof this.arListeners[messageName].pop());
  };

  this.broadcast = function (messageName) {
    var msgArgs;
    if (arguments.length > 1) msgArgs = arguments[1];

    /*if (!confirm('About to broadcast ' + messageName))
      die('fooi');*/

    if (this.arListeners[messageName]) {
      for (var listenerIndex in this.arListeners[messageName]) {
        var listener = this.arListeners[messageName][listenerIndex];
        var listenerFunc = listener['rcv_' + messageName];
        if (typeof listenerFunc == 'function') {
          if (typeof msgArgs != 'undefined') {
            listenerFunc(msgArgs);
          } else listenerFunc();
        }
      }
    }
  };
}

window.msgr = Messenger;
window.msgr = new Messenger();
