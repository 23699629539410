// eslint-disable-next-line no-unused-vars, no-shadow-restricted-names
(function (selectOptions, $, undefined) {
  selectOptions.cloneSelect = function (ele) {
    $(ele)
      .clone()
      .attr('id', _cloneID($(ele)))
      .attr('name', '')
      .hide()
      .appendTo('body');
  };

  selectOptions.populateFromClone = function (ele, dataAttr, rawAttrValues) {
    var hiddenMenu,
      hiddenMenuOptions,
      selectedValue,
      replacementOptions,
      attrValues,
      $ele = $(ele);

    // Make everything strings to ensure indexOf finds it
    attrValues = $.map(rawAttrValues, function (v) {
      return v.toString();
    });
    selectedValue = $ele.val();

    // Find the "complete" set of options, from the hidden
    // copy of the menu.
    hiddenMenu = $('#' + _cloneID($ele));
    hiddenMenuOptions = hiddenMenu.find('option');

    // Build a replacement set of options, cloned from the hidden menu
    replacementOptions = hiddenMenuOptions
      .filter(function () {
        return $(this).val() == '' || attrValues.indexOf($(this).attr(dataAttr)) >= 0;
      })
      .clone();

    // Replace options
    $ele.find('option').remove();
    $ele.append(replacementOptions);

    // Select an option
    $ele.find('option').each(function () {
      if ($(this).val().toString() == selectedValue.toString()) {
        $(this).prop('selected', true);
        return false; //breaks the loop
      }
    });

    // Trigger change if the value changed
    if ($ele.val() != selectedValue) {
      $ele.change();
    }
  };

  function _cloneID($ele) {
    return $ele.attr('name').replace(/\[|\]/g, '_') + '_clone_of_options';
  }
})((window.selectOptions = window.selectOptions || {}), jQuery);

// remove class from select for placeholder effect
$(document).ready(function () {
  $('select.has-placeholder').change(function () {
    $(this).removeClass('has-placeholder');
  });
});
