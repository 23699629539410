import { selectAll, select, scaleOrdinal, pie as d3Pie, arc } from 'd3';
// Pie chart handling
const pieChart = (selector, size = 100, dataObject, colorArray) => {
  if (selectAll('div').empty()) return;

  const width = size,
    height = size,
    margin = 10;
  const radius = size / 2 - margin;

  // append the svg object to the selected div
  const svg = select(selector)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`);

  // set the color scale
  const color = scaleOrdinal().range(colorArray);

  // Compute the position of each group on the pie:
  const pie = d3Pie().value(function (d) {
    return d[1];
  });
  const data_ready = pie(Object.entries(dataObject));

  // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
  svg
    .selectAll('whatever')
    .data(data_ready)
    .join('path')
    .attr('d', arc().innerRadius(0).outerRadius(radius))
    .attr('fill', function (d) {
      return color(d.data[1]);
    });
};

window.renderPieCharts = () => {
  const pies = document.getElementsByClassName('pie');
  for (const pie of pies) {
    // Don't re-render existing charts
    const processed = Array.prototype.slice
      .call(pie.children)
      .reduce((a, c) => a || c.tagName === 'svg', false);
    if (!processed) {
      // Turn data array into object with keys
      const data = {};
      const dataArr = pie.dataset.values.split(',');
      dataArr.forEach((d, i) => {
        data['a' + i] = d;
      });
      const size = pie.dataset.size || 70;
      const colors = pie.dataset.colors.split(',');
      pieChart(pie, size, data, colors);
    }
  }
};

$(document).ready(function () {
  window.renderPieCharts();
});
