const getPageWidth = (defaultValue, miniumum) => {
  try {
    var width = document.getElementById('main').offsetWidth;
    if (miniumum) {
      return miniumum > width ? miniumum : width;
    }
    return width;
  } catch (e) {
    return defaultValue;
  }
};

export default getPageWidth;
