function queryToString(arQuery) {
  var outString = '';
  var field;
  var i;
  var arField;

  for (field in arQuery) {
    outString += field + ': ';
    arField = arQuery[field];
    for (i = 0; i < arField.length; i++) outString += arField[i] + ',';
    outString += '\r\n';
  }

  return outString;
}

function populateDropdownFromQuery(
  theDD,
  arQuery,
  valueFieldName,
  textFieldName,
  insertFirstOption,
  firstOptionValue,
  firstOptionText,
  defaultValue,
) {
  var nextOptionIndex;
  var arDefaults = new Array(1);
  var reDefaults = null;
  var i;
  var thisText;
  var thisVal;

  for (i = theDD.options.length - 1; i >= 0; i--) theDD.options[i] = null;

  if (!arQuery[valueFieldName]) {
    throw 'populateDropdownFromQuery: Value array arQuery[' + valueFieldName + '] does not exist';
  }
  if (!arQuery[textFieldName]) {
    throw 'populateDropdownFromQuery: Text array arQuery[' + textFieldName + '] does not exist';
  }

  if (arQuery[valueFieldName].length > 0) {
    if (insertFirstOption) {
      theDD.options[0] = new Option(firstOptionText, firstOptionValue);
      nextOptionIndex = 1;
    } else nextOptionIndex = 0;

    if (defaultValue) {
      arDefaults = defaultValue.split(',');
      reDefaults = '(';
      for (i = 0; i < arDefaults.length; i++) {
        if (i > 0) reDefaults += '|';
        reDefaults += '^' + arDefaults[i] + '$';
      }
      reDefaults += ')';
      //alert("looking for default values: " + reDefaults);
      // Convert to an actual regular expression object
      reDefaults = new RegExp(reDefaults);
    }

    for (i = 0; i < arQuery[valueFieldName].length; i++) {
      thisText = arQuery[textFieldName][i];
      thisVal = new String(arQuery[valueFieldName][i]);
      theDD.options[nextOptionIndex + i] = new Option(thisText, thisVal);
      //alert('thisVal: ' + thisVal + '\nreDefaults: ' + reDefaults + '\nsearch: ' + thisVal.search(reDefaults));
      if (reDefaults && thisVal.search(reDefaults) != -1)
        theDD.options[nextOptionIndex + i].selected = true;
    }
  }
}

window.queryToString = queryToString;
window.populateDropdownFromQuery = populateDropdownFromQuery;
