/* eslint-disable */
var arRequest = new Array();
var arHandlerFunction = new Array();
var arSpareArgs = new Array();
// Initialize object in non-IE browsers
if (window.XMLHttpRequest) {
  objRequest = new XMLHttpRequest();
}

function newRequestObj() {
  if (window.XMLHttpRequest) {
    // non-IE browser
    return new XMLHttpRequest();
  }
  if (!window.XMLHttpRequest && window.ActiveXObject) {
    // in IE
    return new ActiveXObject('Microsoft.XMLHTTP');
  }
}

function doSyncRequest(URL, method) {
  var requestIndex = arRequest.length;

  // Initialize request object
  arRequest[requestIndex] = newRequestObj();
  arRequest[requestIndex].open(method, URL, false); //false means synchronous
  arRequest[requestIndex].send(null);

  return arRequest[requestIndex];
}

function doRequest(URL, method, handlerFunctionArg, spareArg) {
  /*setTimeout(
    function () {*/

  var requestIndex = arRequest.length;

  //alert(requestIndex);

  // Initialize request object
  arRequest[requestIndex] = newRequestObj();

  arHandlerFunction[requestIndex] = handlerFunctionArg;
  arSpareArgs[requestIndex] = spareArg;

  arRequest[requestIndex].onreadystatechange = function () {
    if (arRequest[requestIndex].readyState == 4) {
      arHandlerFunction[requestIndex](arRequest[requestIndex], arSpareArgs[requestIndex]);
    }
  };
  arRequest[requestIndex].open(method, URL);
  arRequest[requestIndex].send(null);

  /*},
    1);*/
}

function processStateChange() {
  if (arRequest[requestIndex].readyState == 4) {
    handlerFunction(arRequest[requestIndex]);
  }
}

function ArrayDump(theArray) {
  var dumpString = '';
  for (i = 0; i < theArray.length; i++) dumpString += theArray[i] + '\n';
  alert(dumpString);
}

function resultsAsXML(objRequest) {
  var safari =
    typeof document.implementation != 'undefined' &&
    typeof document.implementation.createDocument != 'undefined' &&
    typeof DOMParser == 'undefined';
  if (safari) {
    return objRequest.responseXML;
  } else return makeXMLDoc(objRequest.responseText);
}

window.arRequest = arRequest;
window.arHandlerFunction = arHandlerFunction;
window.arSpareArgs = arSpareArgs;
window.newRequestObj = newRequestObj;
window.doSyncRequest = doSyncRequest;
window.doRequest = doRequest;
window.processStateChange = processStateChange;
window.ArrayDump = ArrayDump;
window.resultsAsXML = resultsAsXML;
