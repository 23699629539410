/* This tool is used by developers to easily submit a particular set of form
  variables, e.g. those from an error report email. This is very useful in
  reproducing reported errors. */
function debugFormSubmission(url, formVars) {
  var form = $('<form>')[0];
  form.action = url;
  form.method = 'post';

  Object.keys(formVars).forEach(function (key) {
    var input = $('<input>')[0];
    input.type = 'hidden';
    input.name = key;
    input.value = formVars[key];
    $(form).append(input);
  });

  $('body').append(form);
  window.csrf.addTokenToForm(form);
  form.submit();
}

window.debugFormSubmission = debugFormSubmission;
