import removeExcessCharacters from './removeExcessCharacters';

const getStandardCannedReportParameters = function () {
  var params = {};
  $('table.settings tbody tr').each(function () {
    var label = removeExcessCharacters($(this).children().first().text()),
      valueCell = $(this).children().last().clone(),
      value = valueCell.html(valueCell.html().replace(/<br[^>]*>/gi, ', ')).text();
    params[label] = value;
  });

  return params;
};
export default getStandardCannedReportParameters;
