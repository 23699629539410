$(document).ready(function () {
  const menuItems = document.querySelectorAll('.has-submenu');

  const expandSubMenu = (item, focusFirst = false) => {
    const subMenu = item.querySelector('nav');
    const firstColumn = item.querySelector('ul');
    const button = item.querySelector('button');

    subMenu.setAttribute('aria-hidden', 'false');
    button.setAttribute('aria-expanded', 'true');
    if (focusFirst) {
      firstColumn.querySelectorAll('a')[0].focus(); // Focus on the first link in the submenu
    }
  };

  const collapseSubMenu = (item, focusButton = false) => {
    const subMenu = item.querySelector('nav');
    const button = item.querySelector('button');

    subMenu.setAttribute('aria-hidden', 'true');
    button.setAttribute('aria-expanded', 'false');
    if (focusButton) {
      button.focus(); // Focus back on the button
    }
  };

  menuItems.forEach(item => {
    const button = item.querySelector('button');

    button.addEventListener('click', () => {
      if (button.ariaExpanded === 'false') {
        expandSubMenu(item);
      } else {
        collapseSubMenu(item);
      }
    });

    item.addEventListener('mouseenter', () => {
      expandSubMenu(item);
    });

    item.addEventListener('mouseleave', () => {
      collapseSubMenu(item);
    });

    // Handling keyboard navigation
    button.addEventListener('keydown', event => {
      if (event.key === 'Enter' || event.key === ' ') {
        // Space or Enter key
        event.preventDefault(); // Prevent the default action to stop scrolling when pressing Space
        if (button.ariaExpanded === 'false') {
          expandSubMenu(item, true);
        } else {
          collapseSubMenu(item, true);
        }
      }
    });

    // Handling tab key inside submenu to loop back to the button
    const subMenuLinks = item.querySelectorAll('ul a');
    if (subMenuLinks.length) {
      const lastLink = subMenuLinks[subMenuLinks.length - 1];
      lastLink.addEventListener('keydown', event => {
        if (event.key === 'Tab' && !event.shiftKey) {
          event.preventDefault();
          button.focus(); // Move focus back to the button
        }
      });
    }
  });
});
