/* eslint indent: 0 */
import d3 from './d3';
import BaseChart from './BaseChart';
import wrap from './wrap';
import isWkHTMLToPDF from './isWkHTMLToPDF';
import getPageWidth from './getPageWidth';

/**
 * wrapper: valid d3 selector
 * dataSeries: A series of series objects
 * colorSeries: A charting.series object for determining colors
 * ticks: An array of tick values
 * options: A charting.Options object
 */
function RatioChart(wrapper, dataSeries, colorSeries, ticks, options, averageCharacterWidth) {
  var self = this;
  // Override default height and width
  self.outerHeight = 500;
  self.outerWidth = getPageWidth(950, 950) - 100;
  self.margin = {
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
  };

  BaseChart.apply(self, [wrapper, 1, options, averageCharacterWidth]);

  self.draw = function () {
    // Data processing

    /* Three categories:
      - group (x-axis) ex: Race
      - yGroup (Bottom of chart rows) ex: Gender
      - xGroup (multiple values per group spread out horizontally) ex: Focus or CG
    */

    const yGroupLabel = dataSeries.name;
    // After we grab the name it's easier to work with the data as an array
    dataSeries = dataSeries.data;

    // Create groups
    const yGroups = dataSeries.map(d => d.name);
    const xGroups = dataSeries[0].labels;

    // Create color scale
    const colorScale = d3.scaleOrdinal().domain(colorSeries.data).range(colorSeries.labels); // Determines color of point

    // Visualize data
    var svg = self.createBaseChart();

    // Create Legend
    const legendHeight = 50;
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('width', self.width)
      .attr('height', legendHeight);

    let legendMargin = 8;
    const legendCharacterWidth = 10;

    // Title
    legend
      .append('text')
      .attr('x', legendMargin)
      .attr('y', legendMargin)
      .attr('style', 'font-size:18px')
      .attr('font-weight', 'bold')
      .text(options.getTitle());

    // Add yGroup Label
    legend
      .append('text')
      .text(yGroupLabel)
      .attr('x', legendMargin)
      .attr('y', legendMargin + 20)
      .attr('line-height', 24)
      .attr('font-size', 16)
      .attr('font-weight', 'bold')
      .attr('alignment-baseline', 'middle');

    let legendX = yGroupLabel.length * legendCharacterWidth + 20;

    // Loop over yGroups and add to legend
    yGroups.forEach((yGroup, i) => {
      // Add shape
      if (i % 2 === 0) {
        legend
          .append('circle')
          .attr('cy', legendMargin + 18)
          .attr('r', 8)
          .attr('cx', legendX + 6)
          .style('fill', '#4f4f4f');
      } else {
        legend
          .append('rect')
          .attr('y', legendMargin + 10)
          .attr('x', legendX)
          .attr('width', 16)
          .attr('height', 16)
          .style('fill', '#4f4f4f');
      }
      // Add text
      legend
        .append('text')
        .text(yGroup)
        .attr('x', legendX + 20)
        .attr('y', legendMargin + 20)
        .attr('line-height', 24)
        .attr('font-size', 16)
        .attr('alignment-baseline', 'middle');
      legendX += yGroup.length * legendCharacterWidth + 40;
    });
    legendX += 20;

    // Add xGroup Label
    legend
      .append('text')
      .text('Groups')
      .attr('x', legendX)
      .attr('y', legendMargin + 20)
      .attr('line-height', 24)
      .attr('font-size', 16)
      .attr('font-weight', 'bold')
      .attr('alignment-baseline', 'middle');
    legendX += 'Groups'.length * legendCharacterWidth + 20;

    // Loop over xGroups and add to legend
    xGroups.forEach(xGroup => {
      // Add colored circle
      legend
        .append('circle')
        .attr('cy', legendMargin + 18)
        .attr('r', 8)
        .attr('cx', legendX + 6)
        .style('fill', colorScale(xGroup));
      // Add text
      legend
        .append('text')
        .text(xGroup)
        .attr('x', legendX + 20)
        .attr('y', legendMargin + 20)
        .attr('line-height', 24)
        .attr('font-size', 16)
        .attr('alignment-baseline', 'middle');
      legendX += xGroup.length * legendCharacterWidth;
    });

    // Chart is shifted for yGroup labels
    const yGroupLabelWidth = d3.max(yGroups, d => d.length) * legendCharacterWidth + 20;
    const chartWidth = self.width - yGroupLabelWidth;

    const groupLabelHeight = 60; // From design doc
    const yGroupLabelHeight = 30 * yGroups.length; // From design doc
    const chartHeight = self.height - legendHeight;

    // Create area for chart
    const chart = svg
      .append('g')
      .attr('transform', `translate(${yGroupLabelWidth},${legendHeight})`)
      .attr('width', chartWidth)
      .attr('height', chartHeight);

    // X-axis labels
    const groups = dataSeries[0].data[0].labels;
    const xScale = d3.scaleBand().range([0, chartWidth]);
    const yScale = d3.scaleLinear().range([chartHeight - yGroupLabelHeight, groupLabelHeight]);

    xScale.domain(groups);
    yScale.domain([ticks[0], ticks[ticks.length - 1]]);

    // Create background zebra bars for groups
    chart
      .selectAll('rect')
      .data(groups)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d))
      .attr('y', 0)
      .attr('width', xScale.bandwidth())
      .attr('height', chartHeight)
      .attr('fill', (d, i) => (i % 2 === 0 ? '#f5f5f5' : '#fff'));

    // Group labels
    chart
      .selectAll('group-label')
      .data(groups)
      .enter()
      .append('text')
      .text(d => d)
      .attr('x', d => xScale(d) + xScale.bandwidth() / 2)
      .attr('y', 20)
      .attr('font-size', 14)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#4f4f4f')
      .call(wrap, xScale.bandwidth() - 10);

    // Add grid lines
    const lines = ticks;
    chart
      .selectAll('grid-lines')
      .data(lines)
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', d => yScale(d))
      .attr('y2', d => yScale(d))
      .attr('stroke', '#1A1A1A')
      .attr('stroke-opacity', 0.1)
      .attr('stroke-dasharray', '4, 4')
      .attr('stroke-width', 2);

    // Add center line
    chart
      .selectAll('grid-lines')
      .data([1])
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', d => yScale(d))
      .attr('y2', d => yScale(d))
      .attr('stroke', '#1A1A1A')
      .attr('stroke-width', 2);

    const borderColor = '#71717A';

    // Add top border
    chart
      .append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', -1)
      .attr('y2', -1)
      .attr('stroke', borderColor)
      .attr('stroke-opacity', 0.1)
      .attr('stroke-width', 2);

    // Add bottom border
    chart
      .append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', chartHeight + 1)
      .attr('y2', chartHeight + 1)
      .attr('stroke', borderColor)
      .attr('stroke-opacity', 0.1)
      .attr('stroke-width', 2);

    // Add left border
    chart
      .append('line')
      .attr('x1', -1)
      .attr('x2', -1)
      .attr('y1', -2)
      .attr('y2', chartHeight + 2)
      .attr('stroke', borderColor)
      .attr('stroke-opacity', 0.1)
      .attr('stroke-width', 2);

    // Add right border
    chart
      .append('line')
      .attr('x1', chartWidth + 1)
      .attr('x2', chartWidth + 1)
      .attr('y1', -2)
      .attr('y2', chartHeight + 2)
      .attr('stroke', borderColor)
      .attr('stroke-opacity', 0.1)
      .attr('stroke-width', 2);

    // Add yGroup dividers
    yGroups.forEach((yGroup, i) => {
      chart
        .append('line')
        .attr('x1', 0)
        .attr('x2', chartWidth)
        .attr('y1', chartHeight - yGroupLabelHeight + i * 30)
        .attr('y2', chartHeight - yGroupLabelHeight + i * 30)
        .attr('stroke', borderColor)
        .attr('stroke-opacity', 0.1)
        .attr('stroke-width', 2);
    });

    // Draw data points
    const pointSize = 16;
    const padding = 40;
    const offsetScale = d3
      .scaleOrdinal()
      .domain(xGroups)
      .range([padding, xScale.bandwidth() - padding]); // Determines offset of point from center

    dataSeries.forEach((yGroupSeries, yIndex) => {
      yGroupSeries.data.forEach(xGroupSeries => {
        let xGroup = xGroupSeries.name;
        const yData = xGroupSeries.data.map((d, i) => ({
          value: d,
          group: xGroupSeries.labels[i],
        }));
        // Alternate points between circles and squares
        if (yIndex % 2 === 0) {
          // Circle
          chart
            .selectAll('point-circle')
            .data(yData.filter(d => d.value != ''))
            .enter()
            .append('circle')
            .attr('cy', d => yScale(d.value))
            .attr('r', pointSize / 2)
            .attr('cx', d => xScale(d.group) + offsetScale(xGroup))
            .style('fill', colorScale(xGroup));
        } else {
          // Square
          chart
            .selectAll('point-rect')
            .data(yData.filter(d => d.value != ''))
            .enter()
            .append('rect')
            .attr('y', d => yScale(d.value) - pointSize / 2)
            .attr('x', d => xScale(d.group) + offsetScale(xGroup) - pointSize / 2)
            .attr('width', pointSize)
            .attr('height', pointSize)
            .style('fill', colorScale(xGroup));
        }
      });
    });

    // Draw lines connecting points
    const lineData = [];
    dataSeries[0].data.forEach((xGroupSeries, xIndex) => {
      let xGroup = dataSeries[0].labels[xIndex];
      const xData = xGroupSeries.data
        .map((d, i) => {
          return {
            startValue: d,
            endValue: dataSeries[1].data[xIndex].data[i],
            group: xGroupSeries.labels[i],
            xGroup,
          };
        })
        .filter(d => d.startValue != '' || d.endValue != '');
      lineData.push(...xData);
    });

    chart
      .selectAll('connecting-lines')
      .data(lineData)
      .enter()
      .append('line')
      .attr('x1', d => xScale(d.group) + offsetScale(d.xGroup))
      .attr('x2', d => xScale(d.group) + offsetScale(d.xGroup))
      .attr('y1', d => yScale(d.startValue != '' ? d.startValue : 1))
      .attr('y2', d => yScale(d.endValue != '' ? d.endValue : 1))
      .attr('stroke', d => colorScale(d.xGroup))
      .attr('stroke-dasharray', '2, 2')
      .attr('stroke-width', 2);

    // Add yGroup labels
    chart
      .selectAll('yGroup-label')
      .data(yGroups)
      .enter()
      .append('text')
      .text(d => d)
      .attr('x', -10)
      .attr('y', (d, i) => chartHeight - yGroupLabelHeight + i * 30 + 15)
      .attr('font-size', 16)
      .attr('alignment-baseline', 'middle')
      .attr('text-anchor', 'end')
      .attr('fill', '#71717A');

    // add yGroup values
    dataSeries.forEach((yGroupSeries, yIndex) => {
      yGroupSeries.data.forEach((xGroupSeries, xIndex) => {
        let xGroup = xGroupSeries.labels[xIndex];
        const xData = xGroupSeries.data.map((d, i) => ({
          value: d,
          group: xGroupSeries.labels[i],
        }));

        chart
          .selectAll('yGroup-values')
          .data(xData)
          .enter()
          .append('text')
          .text(d => (d.value != '' ? d.value : '-'))
          .attr('y', chartHeight - yGroupLabelHeight + yIndex * 30 + 20)
          .attr('x', d => xScale(d.group) + offsetScale(xGroup) - pointSize / 2)
          .style('fill', colorScale(xGroup));
      });
    });

    // Add y-axis
    const yAxis = chart
      .append('g')
      .style('font-size', '14px')
      .style('color', '#1a1a1ab3')
      .call(d3.axisLeft(yScale).tickValues(ticks).tickSize(0));

    yAxis.selectAll('path,line').remove();

    self.drawFooterImage(options, svg);

    if (!isWkHTMLToPDF() && options.getAllowDownload()) {
      this.addDownloadLink(wrapper, averageCharacterWidth);
    }
  };
}
export default RatioChart;
