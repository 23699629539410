/* The BM Canned Report Form (benchmarking/cannedreport/cannedreport_form.cfm)
Not to be confused with the Admin CR Form. */
window.tfk = _.extend(
  window.tfk,
  (function () {
    'use strict';

    // private variables

    var endCycleSelector = 'select#cr_endcycleid';

    // private functions

    /* In addition to this change handler, there are a few others in the CFM
  (`benchmarking/cannedreport/cannedreport_form.cfm`) */
    function cycleIdChanged(event, orderedCycleIDs) {
      var selectedCycleId = parseInt(event.target.value, 10);
      if (!_.isInteger(selectedCycleId)) {
        throw 'Expected integer';
      }
      var preceedingId = preceedingCycleId(selectedCycleId, orderedCycleIDs);
      if (preceedingId !== null) {
        $(endCycleSelector).children('option').prop('disabled', false);
        $(endCycleSelector).val(preceedingId);
        $(endCycleSelector).find('option:selected').prevAll().prop('disabled', true);
      }
    }

    /* @return [Number] - the cycle ID which chronologically preceeds
  `selectedCycleId`, or null if the selected cycle is the oldest. */
    function preceedingCycleId(selectedCycleId, orderedCycleIDs) {
      var selectedIndex = orderedCycleIDs.indexOf(selectedCycleId);
      if (selectedIndex === -1) {
        throw 'Selected cycle not found';
      } else if (selectedIndex === orderedCycleIDs.length - 1) {
        return null; // There is no previous cycle.
      } else {
        return orderedCycleIDs[selectedIndex + 1];
      }
    }

    return {
      // public functions

      // @param orderedCycleIDs [Array] - cycle IDs in ascending chronological order
      initCannedReportForm: function (orderedCycleIDs) {
        var cycleMenu = $('select#cycleID');
        tfk.assertLength(cycleMenu, 1);
        if ($(endCycleSelector).length === 1) {
          cycleMenu.on('change', function (event) {
            cycleIdChanged(event, orderedCycleIDs);
          });
        }
      },
    };
  })(),
);

$(function () {
  'use strict';
  if ($('#bmCRForm').length === 0 || $('#cycleID').length === 0) {
    return;
  }
  var orderedCycleIDs = tfk.getData('bm.cannedReportForm.orderedCycleIDs');
  if (!_.isArray(orderedCycleIDs)) {
    throw 'Expected array';
  }
  window.tfk.initCannedReportForm(orderedCycleIDs);
});
