/* eslint indent: 0 */
import d3 from './d3';
import BaseChart from './BaseChart';
/**
 * wrapper: valid d3 selector
 * series: A charting.series object
 * options: A charting.Options object
 */
function LinearScaleChart(wrapper, dataSeries, colorSeries, options, averageCharacterWidth) {
  var self = this;

  // LinearScale manages its own legend, don't have the base class do anything for it
  options.getShowLegend = function () {
    return false;
  };

  BaseChart.apply(self, [wrapper, 1, options, averageCharacterWidth]);

  self.draw = function () {
    const data = dataSeries.data.map((d, i) => {
      return {
        value: d,
        name: dataSeries.labels[i],
      };
    });

    const labelWidth = (d3.max(data, d => d.name.length) * averageCharacterWidth * 2) / 3 + 20;
    // Force engouh room for labels, but if they want a bigger margin then let them
    if (self.margin.left < labelWidth) {
      self.margin.left = labelWidth;
    }
    self.margin.top = 30;
    self.barWidth = self.width - self.margin.left - self.margin.right;

    var svg = self.createBaseChart();

    // Create scales
    const y = d3
      .scaleBand()
      .range([0, self.height])
      .domain(data.map(d => d.name))
      .padding(0.1);
    svg.append('g').call(d3.axisLeft(y));

    const x = d3.scaleLinear().domain([0, 1]).range([0, self.barWidth]);
    svg
      .append('g')
      .attr('transform', 'translate(0,' + self.height + ')')
      .call(d3.axisBottom(x).tickSize(0).tickValues([]));

    // Create bars
    const color = d3.scaleLinear().domain(colorSeries.data).range(colorSeries.labels);
    svg
      .selectAll('mybar')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', () => x(0))
      .attr('y', d => y(d.name))
      .attr('width', d => (d.value.length ? x(1) : 0))
      .attr('height', y.bandwidth())
      .attr('fill', d => color(d.value));

    const getTextColor = d => {
      const c = d3.rgb(color(d.value));
      return c.r * 0.2126 + c.g * 0.715 + c.b * 0.0722 > 128 ? 'black' : 'white';
    };

    // Add text to bars
    svg
      .selectAll('mytext')
      .data(data)
      .enter()
      .append('text')
      .attr('x', () => x(0.5)) // Adjust the x position based on the value
      .attr('y', d => y(d.name) + y.bandwidth() / 2) // Center the text vertically in the bar
      .attr('dy', '.35em') // to shift the text's baseline for better alignment
      .attr('text-anchor', 'middle')
      .style('fill', getTextColor)
      .style('font-size', '12px')
      .text(d => d.value);

    // Left axis label
    svg
      .append('text')
      .attr('x', -self.height / 2)
      .attr('y', -self.margin.left + 15)
      .attr('transform', 'rotate(-90)')
      .attr('style', 'font-size:12px')
      .style('text-anchor', 'middle')
      .text(options.getYAxisLabel());

    // Create legend
    svg
      .append('text')
      .attr('x', -self.height / 2)
      .attr('y', self.barWidth + 50)
      .attr('transform', 'rotate(-90)')
      .attr('style', 'font-size:12px')
      .attr('text-anchor', 'middle')
      .text(options.getXAxisLabel());

    // Title
    svg
      .append('text')
      .attr('x', (self.width + self.margin.left + self.margin.right) / 2 - self.margin.left)
      .attr('y', -self.margin.top / 2)
      .attr('style', 'font-size:14px')
      .attr('text-anchor', 'middle')
      .text(options.getTitle());

    const colorData = colorSeries.data.map((d, i) => {
      return {
        value: d,
        color: colorSeries.labels[i],
      };
    });

    var extent = d3.extent(colorData, d => d.value);

    var padding = 9;
    var innerWidth = self.height;
    var barHeight = 8;

    var xScale = d3.scaleLinear().range([innerWidth, 0]).domain(extent);

    var xTicks = xScale.ticks(7);

    var yAxis = d3.axisRight(xScale).tickSize(barHeight).tickValues(xTicks);

    var g = svg.append('g').attr('transform', 'translate(' + padding + ', 0)');

    var defs = svg.append('defs');
    var gradientID = wrapper.replace('#', '') + '-legend-gradient';
    var linearGradient = defs.append('linearGradient').attr('id', gradientID);
    linearGradient
      .selectAll('stop')
      .data(colorData)
      .enter()
      .append('stop')
      .attr('offset', d => ((d.value - extent[0]) / (extent[1] - extent[0])) * 100 + '%')
      .attr('stop-color', d => d.color);

    g.append('rect')
      .attr('width', innerWidth)
      .attr('height', barHeight)
      .attr('transform', 'rotate(-90) translate(' + -self.height + ',' + self.barWidth + ')')
      .style('fill', 'url(#' + gradientID + ')');

    g.append('g')
      .call(yAxis)
      .attr('transform', 'translate(' + self.barWidth + ', 0)')
      .select('.domain')
      .remove();
  };
}
export default LinearScaleChart;
