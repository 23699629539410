var Honeybadger = require('@honeybadger-io/js');
Honeybadger.configure({
  apiKey: tfk.honeybadger_api_key,
  environment: tfk.env,
  developmentEnvironments: ['local'],
  revision: 'perpetual_beta',
});
Honeybadger.setContext({
  user_ip: tfk.ipAddress,
});
window.Honeybadger = Honeybadger;
