$(document).ready(function () {
  var iconNameMap = {};
  // Some have an empty string to purposefully not set a title, as they are self explanitory. (Per David's request)
  iconNameMap['arrow-down'] = '';
  iconNameMap['arrow-right'] = '';
  iconNameMap['arrow-up'] = '';
  iconNameMap['arrow-left'] = 'Left';
  iconNameMap['chain-link'] = 'Linked';
  iconNameMap['check'] = '';
  iconNameMap['clipboard-checks'] = 'Check List';
  iconNameMap['clipboard-notes'] = 'Notes';
  iconNameMap['copy-add'] = 'Copy with ' + tfk.label('unit');
  iconNameMap['copy-minus'] = 'Copy without ' + tfk.label('unit');
  iconNameMap['disguise'] = 'Become ' + tfk.label('user');
  iconNameMap['graph-line'] = 'Line Graph';
  iconNameMap['graph-bar'] = 'Bar Graph';
  iconNameMap['info'] = 'Information';
  iconNameMap['magnifier'] = '';
  iconNameMap['pdf'] = '';
  iconNameMap['pivot-top'] = 'Pivot';
  iconNameMap['plus'] = 'Add';
  iconNameMap['role-current-ltd'] = 'Role Current Limited';
  iconNameMap['role-current-unltd'] = 'Role Current Unlimited';
  iconNameMap['role-future-ltd'] = 'Role Future Limited';
  iconNameMap['role-future-unltd'] = 'Role Future Unlimited';
  iconNameMap['role-unassigned'] = 'Role Not Assigned';
  iconNameMap['slide-save'] = 'Save Slide';
  iconNameMap['user'] = tfk.label('user');
  iconNameMap['unit'] = tfk.label('unit');
  iconNameMap['val-fatal'] = 'Fatal';
  iconNameMap['val-serious'] = 'Serious';
  iconNameMap['val-warning'] = 'Warning';

  $('[class^="icon-"],[class*=" icon-"]')
    .filter(function () {
      // Only set titles for icons that don't already have them
      return [undefined, ''].indexOf($(this).attr('title')) != -1;
    })
    .each(function () {
      var $this = $(this);
      // Extract iconName from the `class`es list
      var iconName = $this.attr('class').split('icon-')[1].split(' ')[0];
      var title = '';
      if (Object.prototype.hasOwnProperty.call(iconNameMap, iconName)) {
        title = iconNameMap[iconName];
      } else {
        title = titleize(iconName);
      }

      $this.attr('title', title);
    });

  function titleize(str) {
    return $.map(str.split('-'), function (namePart) {
      return namePart[0].toUpperCase() + namePart.slice(1);
    }).join(' ');
  }
});
