const wordWrap = function (lbl, pxWidth, averageCharacterWidth) {
  if (lbl === undefined) {
    return '';
  }
  var label = lbl.toString();
  // If they already have line breaks, just use them
  if (label.match(/<br\s?\/?>/)) {
    return label;
  }

  var charWidth = Math.floor(pxWidth / averageCharacterWidth);
  var text = label.replaceAll('/', '/ ').replace(/\s+/g, ' ');
  var lines = [];
  var words = text.split(/\s/);

  var line = '';
  $.each(words, function (idx, word) {
    if (line.length) {
      if (word.length == 1 && line.at(-1) == '/') {
        // This happens for things like Latina/o where we want to treat it as a single word
        line += ' ' + word;
      } else if (line.length + 1 + word.length > charWidth) {
        lines.push(line);
        line = word;
      } else {
        line += ' ' + word;
      }
    } else {
      line = word;
    }
  });
  lines.push(line); // The last line kinda gets left hanging
  return lines.join('<br>').replaceAll('/ ', '/');
};
export default wordWrap;
