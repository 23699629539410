window.Cookies = require('vendorJavascriptsDir/js-cookie/js.cookie.js');

import 'javascriptsDir/error_reporting.js';
import 'vendorJavascriptsDir/jquery/jquery.calculation.js';
import 'vendorJavascriptsDir/jquery/jquery.form.js';
import 'vendorJavascriptsDir/jquery/jquery.livequery.js';
import 'vendorJavascriptsDir/jquery/jquery.tipsy.modified.js';
import 'vendorJavascriptsDir/jquery/jquery.tristate.min.js';
import 'javascriptsDir/modernizr-3.6.0-custom.min.js';
import 'vendorJavascriptsDir/jscrollpane/jquery.mousewheel.js';
import 'vendorJavascriptsDir/jscrollpane/mwheelIntent.js';
import 'vendorJavascriptsDir/jsurl/url.min.js';
import 'vendorJavascriptsDir/js-cookie/js.cookie.js';
import 'vendorJavascriptsDir/lodash-4.17.4.min.js';
import 'vendorJavascriptsDir/jquery.deserialize.js';
import 'javascriptsDir/wddxDes.js';
import 'javascriptsDir/decheckable_radios.js';
import 'javascriptsDir/debugging.js';
import 'javascriptsDir/init_datatables_dot_net.js';
import 'javascriptsDir/init_pie_charts.js';
import 'javascriptsDir/global.js';
import 'javascriptsDir/styleswitcher.js';
import 'javascriptsDir/openhelp.js';
import 'javascriptsDir/openwindow.js';
import 'javascriptsDir/lib_xml.js';
import 'javascriptsDir/lib_query.js';
import 'javascriptsDir/lib_httprequest.js';
import 'javascriptsDir/messenger.js';
import 'javascriptsDir/numberformat.js';
import 'javascriptsDir/setcheckboxes.js';
import 'javascriptsDir/setselect.js';
import 'javascriptsDir/wddx.js';
import 'javascriptsDir/livesearch.js';
import 'javascriptsDir/charting.js';
import 'javascriptsDir/tfk/tfk.js';
import 'javascriptsDir/tfk/tfk_lib_util.js';
import 'javascriptsDir/helpWin.js';
import 'javascriptsDir/icontitles.js';
import 'javascriptsDir/topical/bm/canned_reports/form.js';
import 'javascriptsDir/topical/forms/checkbox_list_multi_select.js';
import 'javascriptsDir/topical/forms/select_option_hiding.js';
import 'javascriptsDir/tables.js';
import 'javascriptsDir/session_keep_alive.js';
import 'javascriptsDir/menu.js';
