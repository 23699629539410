function lsAddClass(element, theClass) {
  if (!element.className.match(new RegExp(' ' + theClass + ' '))) {
    element.className = element.className + ' ' + theClass;
  }
}

function lsClearResultsDiv(id) {
  var resultsDiv = document.getElementById(id);
  while (resultsDiv.firstChild) resultsDiv.removeChild(resultsDiv.firstChild);
  resultsDiv.style.display = 'none';
}

function lsCreateResult(value, text) {
  var newDiv = document.createElement('div');
  lsAddClass(newDiv, 'lsResult_inactive');
  newDiv.innerHTML = text;
  newDiv.lsValue = value;
  newDiv.onclick = function (e) {
    if (!e) e = event;
    var el = e.srcElement ? e.srcElement : e.target;
    var parentID = el.parentNode.id;
    var fieldID = parentID.replace(/_lsResults$/, '');
    var field = document.getElementById(fieldID);
    field.lsPickHandler(el.lsValue, el.innerHTML.replace(/'/g, '\''));
    field.lsClearResults();
  };
  newDiv.onmouseover = function (e) {
    if (!e) e = event;
    var el = e.srcElement ? e.srcElement : e.target;
    lsRemoveClass(el, 'lsResult_inactive');
    lsAddClass(el, 'lsResult_active');
  };
  newDiv.onmouseout = function (e) {
    if (!e) e = event;
    var el = e.srcElement ? e.srcElement : e.target;
    lsRemoveClass(el, 'lsResult_active');
    lsAddClass(el, 'lsResult_inactive');
  };
  return newDiv;
}

function lsRemoveClass(element, theClass) {
  element.className = element.className.replace(new RegExp(' ?' + theClass + ' ?'), ' ');
}

function lsSearch(element) {
  var searchString = element.value.replace(/\s+$/, '');
  if (searchString.length >= 4)
    window.doRequest(element.lsSearchURL + searchString, 'GET', element.lsSearchHandler);
  else element.lsClearResults();
}

function setupLiveSearch(field, searchURL, searchHandler, pickHandler) {
  if (!field.id || field.id == '') {
    alert('Can\'t call setupLiveSearch on a field without an id');
    return false;
  }

  // Select all text on focus
  field.onfocus = function () {
    this.select();
  };

  // Store search URL in field object
  field.lsSearchURL = searchURL;

  // Create search results container if it doesn't already exist
  if (!document.getElementById(field.id + '_lsResults')) {
    field.parentNode.appendChild(document.createElement('br'));
    var resultsDiv = document.createElement('div');
    resultsDiv.id = field.id + '_lsResults';
    lsAddClass(resultsDiv, 'lsResults');
    field.parentNode.appendChild(resultsDiv);
  }

  // Assign search function, triggered by 0.5 second pause in typing
  field.lsSearchHandler = searchHandler;
  field.onkeyup = function (e) {
    if (!e) e = event;
    var el = e.srcElement ? e.srcElement : e.target;
    if (el.timeout) clearTimeout(el.timeout);
    el.timeout = setTimeout('lsSearch(document.getElementById("' + el.id + '"))', 500);
  };

  field.lsClearResults = function () {
    lsClearResultsDiv(this.id + '_lsResults');
  };

  field.lsPickHandler = pickHandler;

  field.lsSetResults = function (ar) {
    var resultsDiv = document.getElementById(this.id + '_lsResults');
    while (resultsDiv.firstChild) resultsDiv.removeChild(resultsDiv.firstChild);
    resultsDiv.style.display = 'block';

    if (ar['VALUE'].length == 0) {
      var noMatchText = document.createTextNode('No matches');
      resultsDiv.appendChild(noMatchText);
      setTimeout(function () {
        lsClearResultsDiv(resultsDiv.id);
      }, 2500);
    } else {
      resultsDiv.innerHTML = '';
      for (var i = 0; i < ar['VALUE'].length; i++) {
        resultsDiv.appendChild(lsCreateResult(ar['VALUE'][i], ar['TEXT'][i]));
      }
    }
  };
}

window.lsAddClass = lsAddClass;
window.lsClearResultsDiv = lsClearResultsDiv;
window.lsCreateResult = lsCreateResult;
window.lsRemoveClass = lsRemoveClass;
window.lsSearch = lsSearch;
window.setupLiveSearch = setupLiveSearch;
