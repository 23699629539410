/* eslint-disable */
// innerText
//
// by Dethe Elza (delza@livingcode.org)
// Senior Technical Architect, Blast Radius
//
// from IBM developerWorks guest column
// 20 May 2005
//
// extract all the text nodes contained in a node
function innerText(node) {
  // is this a text or CDATA node?
  // we use integers instead of ie. Node.TEXT_NODE or Node.CDATA_SECTION_NODE
  // because IE does not yet define Node
  if (node.nodeType == 3 || node.nodeType == 4) {
    return node.data;
  }
  var i;
  var returnValue = [];
  for (i = 0; i < node.childNodes.length; i++) {
    returnValue.push(innerText(node.childNodes[i]));
  }
  return returnValue.join('');
}

// Turns an XML string into a DOM-compatible XML object
// 2004/07/14 - Leon Miller-Out
function makeXMLDoc(rawXML) {
  var xmlDoc;
  var moz =
    typeof document.implementation != 'undefined' &&
    typeof document.implementation.createDocument != 'undefined';
  var ie = typeof window.ActiveXObject != 'undefined';
  var safari = moz && typeof DOMParser == 'undefined';

  if (safari) {
    alert("This function doesn't work with Safari");
    throw 'BrowserNotSupported';
  } else if (moz) {
    xmlDoc = document.implementation.createDocument('', '', null);
    xmlDoc = new DOMParser().parseFromString(rawXML, 'text/xml');
  } else if (ie) {
    xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
    xmlDoc.async = false;
    while (xmlDoc.readyState != 4) {}
    xmlDoc.loadXML(rawXML);
  }

  return xmlDoc;
}

// Turns a WDDX RecordSet (in XML form) into an hash (associative array) of arrays, where the keys
// of the hash are the column names of the recordset, and the value for each hash key is an array
// containing the values for that column.
// 2004/07/14 - Leon Miller-Out
function wddxRSToArray(wddxXML) {
  var fieldList = wddxXML.getElementsByTagName('field');
  var arRS = new Array();
  var fieldCounter;
  var thisFieldNode;
  var thisFieldName;
  var valueList;
  var valueCounter;
  var thisValueNode;
  var thisTextNode;
  var thisValue;

  if (fieldList.length == 0)
    throw 'Error retrieving data from server.\nwddxRSToArray: No FIELD elements were found in wddxXML';

  for (fieldCounter = 0; fieldCounter < fieldList.length; fieldCounter++) {
    //alert('found field ' + fieldList.item(i).attributes.getNamedItem('name').nodeValue);
    thisFieldNode = fieldList.item(fieldCounter);
    thisFieldName = thisFieldNode.attributes.getNamedItem('name').nodeValue;
    arRS[thisFieldName] = new Array();
    valueList = thisFieldNode.childNodes;
    for (valueCounter = 0; valueCounter < valueList.length; valueCounter++) {
      thisValueNode = valueList.item(valueCounter);
      thisValue = '';
      switch (thisValueNode.nodeName) {
        case 'number':
          if (thisValueNode.hasChildNodes()) {
            thisTextNode = thisValueNode.firstChild;
            thisValue = thisTextNode.data;
            // Strip trailing zeros from integers. Don't use parseInt here because it will truncate numbers in scientific notation.
            if (Math.round(thisValue) == thisValue) thisValue = Math.round(thisValue);
          }
          break;
        case 'string':
          if (thisValueNode.hasChildNodes()) {
            thisValue = innerText(thisValueNode);
          }
          break;
        case 'boolean':
          thisValue = thisValueNode.attributes.getNamedItem('value').nodeValue;
          break;
        case 'null':
          break;
        default:
          throw thisValueNode.nodeName + ' is not a datatype I understand.';
      }
      arRS[thisFieldName][arRS[thisFieldName].length] = thisValue;
    }
  }

  return arRS;
}

window.innerText = innerText;
window.makeXMLDoc = makeXMLDoc;
window.wddxRSToArray = wddxRSToArray;
