import d3 from './d3';
import BaseBarChart from './BaseBarChart';

const StackedBarChart = function (
  wrapper,
  barSeries,
  pntSeries,
  lineSeries,
  options,
  averageCharacterWidth
) {
  var self = this;

  var legendItemsPerRow = options.getItemsPerLegendRowWhenHorizontal();
  if (legendItemsPerRow == 0) {
    legendItemsPerRow = barSeries.length;
  }
  var nLegendRows = Math.ceil(barSeries.length / legendItemsPerRow);

  if (options.getShowLegend() && options.getLegendDirection() == 'horizontal') {
    self.largestSeriesSetSize = nLegendRows;
  }

  // Apply the constructor of the "Parent" to `this` instance
  BaseBarChart.apply(self, [
    wrapper,
    barSeries,
    pntSeries,
    lineSeries,
    options,
    averageCharacterWidth,
  ]);

  if (options.hasMaxBarWidth()) {
    self.maxBarWidth = options.getMaxBarWidth();
  } else {
    self.maxBarWidth = 200;
  }
  self.chartClass = 'stacked-bar-chart';

  self.drawBarLegend = function (svg, legendPosition) {
    var lX, lY, legend, bar, w;
    switch (options.getLegendDirection()) {
      case 'vertical':
        switch (legendPosition) {
          case 'bottom':
            lX = 0;
            lY = self.outerHeight - barSeries.length * self.legendItemHeight - 14;
            break;
          case 'right':
            lX = self.outerWidth - options.getLegendWidth();
            lY = self.margin.top + 25;
            break;
          case 'top':
            lX = 0;
            lY = 10 - self.margin.top;
            break;
        }
        legend = svg.append('g').attr('class', 'legend');
        bar = legend.append('g');
        var h = self.legendItemHeight;
        w = 5;
        for (let i = 0; i < barSeries.length; i++) {
          var baseheight = lY + (barSeries.length - i) * h;
          bar
            .append('g')
            .attr('class', self.seriesClass('bar', i))
            .append('rect')
            .attr('height', h)
            .attr('width', w)
            .attr('x', lX)
            .attr('y', baseheight - (h - 3));
          legend
            .append('text')
            .attr('x', lX + 3 + w)
            .attr('y', baseheight)
            .text(barSeries[i].name);
        }
        break;

      case 'horizontal':
        var itemsPerRow = legendItemsPerRow;
        var nRows = nLegendRows;
        var labelRowHeight = 14;
        lX = 0;
        lY = self.outerHeight - self.legendItemHeight - labelRowHeight * nRows;
        legend = svg.append('g').attr('class', 'legend');
        bar = legend.append('g');
        w = 10;
        var itemWidth = (self.outerWidth - 20) / itemsPerRow;
        for (let i = 0; i < barSeries.length; i++) {
          var thisLY = lY + (Math.ceil((i + 1) / itemsPerRow) - 1) * labelRowHeight;
          bar
            .append('g')
            .attr('class', self.seriesClass('bar', i))
            .append('rect')
            .attr('height', w)
            .attr('width', w)
            .attr('x', lX + (i % itemsPerRow) * itemWidth)
            .attr('y', thisLY - w);
          legend
            .append('text')
            .attr('x', lX + (i % itemsPerRow) * itemWidth + 3 + w)
            .attr('y', thisLY)
            .text(barSeries[i].name);
        }
        break;
    }
  };

  // Creates D3 data "layers" from the array of Series that was passed in
  self.layersFromArrayOfCols = function (d) {
    var l = [];
    var lowerBound = [];
    d3.range(d.length).map(function (cNum) {
      l[cNum] = [];
      d3.range(d[0].len()).map(function (lNum) {
        if (lowerBound[lNum] == undefined) {
          lowerBound[lNum] = 0;
        }
        l[cNum][lNum] = {
          x: lNum,
          y: parseFloat(d[cNum].data[lNum]),
          y0: lowerBound[lNum],
          name: d[cNum].name,
        };
        lowerBound[lNum] += parseFloat(d[cNum].data[lNum]);
      });
    });
    return l;
  };
};

export default StackedBarChart;
