import d3 from './d3';
import BaseBarChart from './BaseBarChart';
function FloatingStackedBarChart(
  wrapper,
  barSeries,
  pntSeries,
  lineSeries,
  options,
  averageCharacterWidth
) {
  var self = this;
  // Apply the constructor of the "Parent" to `this` instance
  BaseBarChart.apply(self, [
    wrapper,
    barSeries,
    pntSeries,
    lineSeries,
    options,
    averageCharacterWidth,
  ]);

  if (options.hasMaxBarWidth()) {
    self.maxBarWidth = options.getMaxBarWidth();
  } else {
    self.maxBarWidth = 200;
  }
  self.chartClass = 'floating-stacked-bar-chart';

  self.drawBarLegend = function (svg, legendPosition) {
    var lX, lY;
    switch (legendPosition) {
      case 'bottom':
        lX = 0;
        lY = self.outerHeight - barSeries.length * self.legendItemHeight - 14;
        break;
      case 'right':
        lX = self.outerWidth - options.getLegendWidth();
        lY = self.margin.top + 25;
        break;
      case 'top':
        lX = 0;
        lY = 10 - self.margin.top;
        break;
    }
    var legend = svg.append('g').attr('class', 'legend');
    var bar = legend.append('g');
    var h = self.legendItemHeight;
    var w = 5;
    for (var i = 0; i < barSeries.length; i++) {
      var baseHeight = lY + (barSeries.length - i) * h;
      if (i != barSeries.length - 1) {
        bar
          .append('g')
          .attr('class', self.seriesClass('bar', i))
          .append('rect')
          .attr('height', h)
          .attr('width', w)
          .attr('x', lX)
          .attr('y', baseHeight - h);
      }
      legend
        .append('text')
        .attr('x', lX + 3 + w)
        .attr('y', baseHeight + h / 4)
        .text(barSeries[i].name);
    }
  };

  // Creates D3 data "layers" from the array of Series that was passed in
  self.layersFromArrayOfCols = function (d) {
    var l = [];
    d3.range(d.length - 1).map(function (cNum) {
      l[cNum] = [];
      d3.range(d[0].len()).map(function (lNum) {
        l[cNum][lNum] = {
          x: lNum,
          y: parseFloat(d[cNum + 1].data[lNum] - d[cNum].data[lNum]),
          y0: parseFloat(d[cNum].data[lNum]),
        };
      });
    });
    return l;
  };
}
export default FloatingStackedBarChart;
