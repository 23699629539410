/* eslint indent: 0 */
import d3 from './d3';
import saveChartAsImage from 'javascriptsDir/charting/saveChartAsImage';
import wordWrap from './wordWrap';
/**
 * wrapper: valid d3 selector
 * largestSeriesSetSize: A number reperesenting the length of the largest series set
 * options: A charting.Options object
 */
function BaseChart(wrapper, largestSeriesSetSize, options, averageCharacterWidth) {
  var self = this;
  self.legendItemHeight = 13;
  self.legendItemSpacing = 2;
  self.footerImageHeight = 20;
  if (self.margin == undefined) {
    var btmMargin = 30,
      lftMargin = 70,
      rightMargin = 10,
      topMargin = 10;
    if (options.getShowLegend() && !options.getGroupBySeries()) {
      // Todo: we need to know how big the legend is going to be, including wrapped labels.
      // We can't know that until we've drawn the chart, so we'll have to come back to this.
      var legendHeight = (self.legendItemHeight + self.legendItemSpacing) * largestSeriesSetSize;
      switch (options.getLegendPosition()) {
        case 'bottom':
          btmMargin += 30 + legendHeight;
          break;
        case 'right':
          rightMargin += 120;
          if (!options.getInvertAxes() && options.getSecondYAxis()) {
            rightMargin += lftMargin; // Left Margin accounts for the Y axis
          }
          break;
        case 'top':
          topMargin += 10 + legendHeight;
          if (options.getInvertAxes() && options.getSecondYAxis()) {
            topMargin += 20;
          }
          break;
      }
    }
    if (options.getInvertAxes()) {
      // We'll assume that self.primarySeriesFirstSet is defined by this point.
      var lblLength = options.hasLongestLabel()
        ? options.getLongestLabel()
        : self.primarySeriesFirstSet.longestLabel();
      lftMargin = Math.max(lblLength * 5 + 15, 70); //Width of largest x axis label
      rightMargin = 30; // Ensure space for "y axis" (now x axis) labels
      if (options.hasYAxisLabel()) {
        btmMargin += 20;
      }
    } else if (options.hasXAxisLabel()) {
      btmMargin += 20;
    }
    self.margin = {
      top: topMargin,
      right: rightMargin,
      bottom: btmMargin,
      left: lftMargin,
    };
  }
  if (self.outerWidth == undefined) {
    self.outerWidth = 700;
  }
  if (self.outerHeight == undefined) {
    self.outerHeight = 400;
  }

  if (options.hasFooterImage()) {
    self.margin.bottom += self.footerImageHeight;
  }

  // Allow size overrides
  if (options.hasValidBoxModel()) {
    $.extend(true, self, options.getBoxModel());
  }

  self.width = self.outerWidth - self.margin.left - self.margin.right;
  self.height = self.outerHeight - self.margin.top - self.margin.bottom;

  /* Attach the chart options to the wrapper so the saveChartAsImage function
      can use some of them -isaiah 2013-12-13 */
  $(wrapper).data('options', options);

  // Prepare series numbering
  var seriesNum = options.getSeriesNumStartOffset();
  var seriesAssigned = {};
  self.seriesClass = function (type, n) {
    var key = type + n;
    var sNum;
    if (key in seriesAssigned) {
      sNum = seriesAssigned[key];
    } else {
      seriesNum++;
      seriesAssigned[type + n] = seriesNum;
      sNum = seriesNum;
    }
    return type + ' ' + type + '-' + n + ' series-' + sNum;
  };

  self.adjustYAxisTicks = function (yAxis, options, ystackDiff) {
    if (options.hasYTickCount()) {
      yAxis.ticks(options.getYTickCount());
    } else {
      var decimalMultiplier = 1 * Math.pow(10, options.getValueDisplayDecimalPlaces() * -1);
      if (ystackDiff / 10 < decimalMultiplier) {
        // We add one here for "fence post" math :) (e.g. if the difference in your stack is
        // 0-2 with a 1 decimal multiplier, you want 3 ticks: 0, 1, and 2)
        yAxis.ticks(Math.floor(ystackDiff / decimalMultiplier) + 1);
      } else if (options.getInvertAxes()) {
        yAxis.ticks(6); //When inverted use less ticks to reduce chance of overlap
      }
    }
  };

  /* Translates HTML br to SVG "new lines" (separate text
      objects placed below their parent) -isaiah 2013-05-02 */
  self.br2nlForLabels = function (labels) {
    labels.map(function (n) {
      var tick = d3.select(n);
      // Get the last `text` child
      var txtFields = tick.selectAll('text');
      var origTxt = txtFields.filter(function (d, i) {
        return i === txtFields.nodes().length - 1;
      });
      if (origTxt.nodes().length > 0) {
        var txtLines = origTxt.text().split(/<br\s?\/?>/);
        var numLines = txtLines.length;
        if (numLines > 1) {
          origTxt.text(txtLines[0]);
          for (var i = 1; i < numLines; i++) {
            var prevDY = parseFloat(origTxt.attr('dy'));
            if (isNaN(prevDY)) {
              prevDY = 0;
            }
            tick.node().appendChild(
              d3
                .select(origTxt.node().cloneNode())
                .text(txtLines[i])
                .attr('dy', i + prevDY + 'em')
                .node(),
            );
          }
        }
      }
    });
  };

  self.convertBoldInLabels = function (labels) {
    labels.map(function (n) {
      var tick = d3.select(n),
        origTxt = tick.select('text');
      if (origTxt.nodes().length > 0) {
        var text = origTxt.text().trim();
        if (text.slice(0, 3) == '<b>' && text.slice(-4) == '</b>') {
          origTxt.text(text.slice(3, -4)).style('font-weight', 'bold');
        }
      }
    });
  };

  self.formatLabels = function (labels) {
    self.convertBoldInLabels(labels);
    self.br2nlForLabels(labels);
  };

  self.createBaseChart = function () {
    var ret = d3
      .select(wrapper)
      .append('svg')
      .attr('width', self.width + self.margin.left + self.margin.right)
      .attr('height', self.height + self.margin.top + self.margin.bottom)
      .attr('class', self.chartClass)
      .attr('role', 'img')
      .attr('aria-label', 'Chart: ' + options.getTitle())
      .style('overflow', 'visible')
      .append('g')
      .attr('transform', 'translate(' + self.margin.left + ',' + self.margin.top + ')')
      .attr('class', 'chart-wrapper');
    var defs = d3.select(wrapper).select('svg').append('defs');

    //Chevron
    var chev = defs
      .append('pattern')
      .attr('id', 'pattern-chev')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 5)
      .attr('height', 9)
      .attr('x', 0)
      .attr('y', 0)
      .attr('viewBox', '0 0 10 18');
    var chevG = chev.append('g').attr('id', 'chevron');
    chevG.append('path').attr('d', 'M0 0l5 3v5l-5 -3z').attr('class', 'pat-chev');
    chevG.append('path').attr('d', 'M10 0l-5 3v5l5 -3').attr('class', 'pat-chev');
    chev.append('use').attr('x', 0).attr('y', 3).attr('xlink:href', '#chevron');

    //Checkered
    var chek = defs
      .append('pattern')
      .attr('id', 'pattern-check')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 10)
      .attr('height', 10)
      .attr('x', 0)
      .attr('y', 0);
    chek
      .append('rect')
      .attr('width', 5)
      .attr('height', 5)
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', 'pat-check');
    chek
      .append('rect')
      .attr('width', 5)
      .attr('height', 5)
      .attr('x', 5)
      .attr('y', 5)
      .attr('class', 'pat-check');

    //Dots
    var dots = defs
      .append('pattern')
      .attr('id', 'pattern-dots')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 10)
      .attr('height', 10)
      .attr('x', 0)
      .attr('y', 0);
    var dotsG = dots.append('g').attr('id', 'dots');
    dotsG
      .append('rect')
      .attr('width', 10)
      .attr('height', 10)
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', 'pat-dots');
    dotsG
      .append('circle')
      .attr('cx', 5)
      .attr('cy', 5)
      .attr('r', 2)
      .attr('class', 'pat-dots-invert');
    dotsG
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 2)
      .attr('class', 'pat-dots-invert');
    dotsG
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 0)
      .attr('r', 2)
      .attr('class', 'pat-dots-invert');
    dotsG
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 10)
      .attr('r', 2)
      .attr('class', 'pat-dots-invert');
    dotsG
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 10)
      .attr('r', 2)
      .attr('class', 'pat-dots-invert');

    //Diamonds
    var dias = defs
      .append('pattern')
      .attr('id', 'pattern-diamonds')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 10)
      .attr('height', 10)
      .attr('x', 0)
      .attr('y', 0);
    var diasG = dias.append('g').attr('id', 'diamonds');
    diasG
      .append('rect')
      .attr('width', 10)
      .attr('height', 10)
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', 'pat-diamonds');
    diasG
      .append('rect')
      .attr('width', 5.4)
      .attr('height', 5.4)
      .attr('x', 2.3)
      .attr('y', 2.3)
      .attr('transform', 'rotate(45, 5, 5)')
      .attr('class', 'pat-diamonds-invert');

    return ret;
  };

  self.drawBasicColorLegend = function (svg, series, lX, lY, seriesType, sharingWithOtherLegend) {
    var legend = svg.append('g').attr('class', 'legend');
    var bar = legend.append('g');
    var h = self.legendItemHeight;
    var w = 15;
    var lbls = self.seriesLabels(series);
    var sLen = lbls.length;
    var labelWidth = sharingWithOtherLegend ? self.outerWidth / 4 : self.outerWidth / 2;
    var labelY = lY;
    for (var i = 0; i < sLen; i++) {
      var itemName = lbls[i];
      bar
        .append('g')
        .attr('class', self.seriesClass(seriesType, i))
        .append('rect')
        .attr('height', h)
        .attr('width', w)
        .attr('rx', h)
        .attr('ry', w)
        .attr('x', lX)
        .attr('y', labelY - h + 2);
      var thisLabel = legend.append('g');
      var labelText = self.wordWrap(itemName, labelWidth);
      var labelRows = labelText.split('<br>').length;
      thisLabel
        .append('text')
        .attr('x', lX + 8 + w)
        .attr('y', labelY)
        .text(labelText);
      labelY += h + self.legendItemSpacing;
      labelY += h * (labelRows - 1);
      self.formatLabels(thisLabel.nodes());
      if ($(thisLabel[0]).children('text').length > 1) {
        $(thisLabel[0])
          .children('text')
          .each(function () {
            $(this).attr('y', $(this).attr('y') - h / 2);
          });
      }
    }
  };

  self.drawFooterImage = function (options, svg) {
    if (options.hasFooterImage()) {
      const footerImage = svg
        .append('svg:image')
        .attr('xlink:href', options.getFooterImage())
        .attr('height', self.footerImageHeight)
        .attr('x', 10)
        .attr('y', self.outerHeight - self.margin.top - self.footerImageHeight);
      // Reposition once image is loaded to the proper width
      $(() => {
        const footerImageWidth = footerImage.style('width').replace('px', '');
        footerImage.attr('x', self.width - footerImageWidth);
      });
    }
  };

  self.seriesLabels = function (series) {
    var setOfSeries = series instanceof Array;
    return setOfSeries
      ? $.map(series, function (s) {
          return s.name;
        })
      : series.labels;
  };

  self.seriesNumberFromJ = function (j, seriesType) {
    if (seriesType == undefined) {
      seriesType = 'series';
    }
    var regex = new RegExp('^.*' + seriesType + '-(\\d+).*$', 'i');
    return j[0].parentElement.className.baseVal.match(regex)[1];
  };

  self.formatToolTipValue = function (d) {
    return self.formatValueToDP(d, options.getValueToolTipDecimalPlaces());
  };

  // Format the values for Y Axis Tick Labels
  self.formatValue = function (d) {
    return self.formatValueToDP(d, options.getValueDisplayDecimalPlaces());
  };

  self.formatValueToDP = function (d, dp) {
    return (
      options.getValueDisplayPrefix() +
      d3.format(',.' + dp + 'f')(d) +
      options.getValueDisplaySuffix()
    );
  };

  self.getTranslation = function (transform) {
    // Create a dummy g for calculation purposes only. This will never
    // be appended to the DOM and will be discarded once this function
    // returns.
    var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    // Set the transform attribute to the provided string value.
    g.setAttributeNS(null, 'transform', transform);

    // consolidate the SVGTransformList containing all transformations
    // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
    // its SVGMatrix.
    var matrix = g.transform.baseVal.consolidate().matrix;

    // As per definition values e and f are the ones for the translation.
    return [matrix.e, matrix.f];
  };

  self.getXScaleFromXValue = function (xVal, xScales) {
    if (xScales.length == 1) {
      return xScales[0];
    }
    for (var i = 0; i < xScales.length; i++) {
      if (xScales[i].domain().indexOf(xVal) != -1) {
        return xScales[i];
      }
    }
    return xScales[0];
  };

  self.removeDefaultStyleAttrsFromAxesAndWrapper = function () {
    d3.selectAll('g.axis').attr('fill', null).attr('font-family', null).attr('font-size', null);
    d3.selectAll('g.chart-wrapper')
      .attr('fill', null)
      .attr('font-family', null)
      .attr('font-size', null)
      .attr('text-anchor', null);
  };

  /* Crude word wrap based on an "Average" width of characters, as
      SVG doesn't support wordwrapping fully */
  self.wordWrap = function (lbl, pxWidth) {
    return wordWrap(lbl, pxWidth, averageCharacterWidth);
  };

  self.addDownloadLink = function (wrapper, charWidth) {
    const downloadLink = $('<a>')
      .attr('href', '#')
      .text(`Download Image of ${options.getTitle() ? options.getTitle() : 'Chart'}`)
      .click(e => {
        e.preventDefault();
        saveChartAsImage(wrapper, e.target, charWidth);
      });
    const isMonocle = $(wrapper).closest('.monocle').length;
    if (isMonocle) {
      const $chartAndTitleWrapper = $(wrapper).closest('.chart-with-title');
      if ($chartAndTitleWrapper.length) {
        downloadLink.attr('class', 'btn btn-secondary');
        $chartAndTitleWrapper.append(downloadLink);
        return;
      }
    }

    downloadLink.css({
      'margin-left': '10px',
      'margin-bottom': '20px',
      display: 'block',
    });
    $(wrapper).append(downloadLink);
  };

  // Expand SVG size to match the internal chart wrapper size
  self.adjustSVGBounds = function (svg) {
    const wrapperBounds = svg.node().getBBox();
    const totalHeight = wrapperBounds.height;
    $(wrapper + ' svg').attr('height', totalHeight);
  };
}
export default BaseChart;
