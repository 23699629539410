/* global Cookies */

$(function () {
  var sessionTimeout = tfk.sessionTimeoutInMinutes * 60, // Seconds for session, should match Application.cfc
    warningBuffer = 300, // Seconds before timeout to warn the user
    warningCheckLoopTime = 5, // Seconds between checks
    cookieName = 'lastActionAtUTC'.toUpperCase(),
    warningIsVisible = false,
    wrapper = $('<div>').hide().appendTo('body').addClass('warning').css({
      position: 'fixed',
      right: 20,
      top: '40%',
      width: 250,
      zIndex: 20,
    }),
    warnIfSessionCloseToEnding = function () {
      if (Cookies.get(cookieName)) {
        var lastActionAt = parseInt(Cookies.get(cookieName)),
          curTime = Date.now() / 1000, // Convert to seconds
          timeLeftInSession = lastActionAt + sessionTimeout - curTime;
        if (warningIsVisible && timeLeftInSession < 0) {
          // Warning was ignored, and now it's too late.
          showSessionEnded('Your session has expired due to inactivity.');
          clearInterval(checkLoop);
        } else if (!warningIsVisible && timeLeftInSession < warningBuffer) {
          // Show modal, with button to "Keep session active" that calls `keepSessionAlive()`
          showKeepAlive();
          warningIsVisible = true;
        }
      } else {
        /* If that cookie is missing, then they logged out or something */
        clearInterval(checkLoop);
      }
    },
    keepSessionAlive = function () {
      $.getJSON(
        '/app/controllers/session_keep_alive_controller.cfc',
        { method: 'ping' },
        function (data) {
          if (data.SUCCESS) {
            // eslint-disable-next-line no-console
            console.log('extended session');
            wrapper.find('button').remove();
            wrapper.find('p').text('Session has been extended');
            wrapper.delay(5000).fadeOut(500);
            warningIsVisible = false;
          } else {
            showSessionEnded(
              'Unfortunately your session expired before we were able to extend it.',
            );
            clearInterval(checkLoop);
          }
        },
      );
    },
    showKeepAlive = function () {
      wrapper
        .html('')
        .append($('<h3>').text('Session'))
        .append($('<p>').text('Your session is about to end, please click below to extend it.'))
        .append($('<button>').on('click', keepSessionAlive).text('Extend Session'))
        .show();
    },
    showSessionEnded = function (msg) {
      wrapper
        .html('')
        .append($('<h3>').text('Session Timed Out'))
        .append(
          $('<p>').text(
            msg +
              ' If you have any unsaved changes on this page, when you save ' +
              'this page you will be asked to log in again. Once you log back in your changes will be saved.',
          ),
        )
        .show();
    },
    checkLoop = setInterval(warnIfSessionCloseToEnding, warningCheckLoopTime * 1000);
});
