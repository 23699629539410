$(document).ready(function () {
  var centerWideTables = function () {
    var containerWidth = $('#main').width(),
      windowWidth = $(window).width();

    $('table.tabular, div.columnarlist table')
      .change(function () {
        if ($(this).parent().hasClass('table-scroll-box')) {
          return; // Skip this table
        }

        // fixedheader tables wrap themselves with a div, so ascend the check
        if (
          $(this).parent().hasClass('fixedheader') &&
          $(this).parent().parent().hasClass('table-scroll-box')
        ) {
          return; // Skip this table
        }

        if ($(this).closest('#win_main').length > 0) {
          return; // This is in a pop up window, which doesn't have centered content.
        }

        if ($(this).parent().is('td')) {
          // Don't try to center a table that's inside another table. It will break the layout.
          return;
        }

        var tableWidth = $(this).width();
        if (tableWidth > containerWidth) {
          var mrg;
          if (windowWidth > tableWidth) {
            mrg = (tableWidth - containerWidth) / 2;
          } else if (windowWidth > containerWidth) {
            mrg = (windowWidth - containerWidth) / 2 - 20;
          } else {
            mrg = 0;
          }
          var $target = $(this);
          if ($target.closest('.ui-corner-all').length > 0) {
            $target = $target.closest('.ui-corner-all');
          } else if ($target.closest('div.columnarlist').length > 0) {
            $target = $target.closest('div.columnarlist');
          } else if ($target.closest('div.fixedheader').length > 0) {
            $target = $target.closest('div.fixedheader');
          }
          $target.css('margin-left', -1 * mrg + 'px');
        }
      })
      .change();
  };
  centerWideTables();

  $(window).resize(centerWideTables);
});
